<template>
  <div class="ma-0 pa-0 d-flex flex-row align-content-center">
    <div class="ma-0 pa-0 d-flex flex-column justify-center">
      <p class="ma-0 mr-2 pa-0">Viewing:</p>
    </div>
    <AdsSelect
      class="mt-0"
      v-model="selected"
      :items="items"
      return-object
      hide-details
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { formatDate } from '@/utils';
import { USER_TYPE } from '@/data/constants';
import { AdsSelect } from '@nswdoe/doe-ui-core';

export default {
  name: 'PdpSelect',
  components: {
    AdsSelect,
  },
  props: {
    userType: {
      type: String,
      default: USER_TYPE.owner,
    },
  },
  data: () => ({
    selected: null,
    items: null,
  }),
  computed: {
    ...mapGetters([
      'loggedInUser',
      'ownedPdp',
      'schoolPdp',
      'actionPdp',
      'archiveManagementPdp',
    ]),
    paramPdpId() {
      return this.$route.params.pdpId
        ? parseInt(this.$route.params.pdpId)
        : undefined;
    },
    paramSelectedTab() {
      return this.$route.params.selectedTab;
    },
    hasActivePdp() {
      return this.ownedPdp?.filter((pdp) => pdp.active)?.length > 0;
    },
  },
  watch: {
    paramPdpId: {
      handler() {
        if (
          this.paramPdpId &&
          this.ownedPdp &&
          this.selected?.value !== this.paramPdpId
        ) {
          this.selected = this.items?.find(
            (item) => item.value === this.paramPdpId
          );
        } else if (!this.paramPdpId && this.ownedPdp) {
          this.selected = null;
        }
      },
      immediate: true,
    },
    items: {
      handler() {
        switch (this.userType) {
          case USER_TYPE.owner:
            if (
              this.items?.length &&
              !this.selected &&
              !this.paramPdpId &&
              this.hasActivePdp
            ) {
              this.selected = this.items[0];
            } else if (
              this.items?.length &&
              !this.selected &&
              this.paramPdpId
            ) {
              this.selected = this.items?.find(
                (item) => item.value === this.paramPdpId
              );
            } else if (
              this.items?.length &&
              this.selected &&
              !this.paramPdpId
            ) {
              this.selected = null;
            }
            break;
          case USER_TYPE.supervisor:
            if (this.items?.length && !this.selected && this.paramPdpId) {
              this.selected = this.items?.find(
                (item) => item.value === this.paramPdpId
              );
            }
            break;
          default:
            break;
        }
      },
      immediate: true,
    },
    selected: {
      async handler() {
        if (this.selected) {
          this.$emit('selected', this.selected?.value);
        }
      },
      immediate: true,
    },
    ownedPdp: {
      handler() {
        if (this.ownedPdp) {
          this.setItems();
        } else {
          this.items = null;
          this.selected = null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('pdp', ['getPdpManagement']),
    displayPdpValue(pdp) {
      return `${
        !pdp.active
          ? `ARCHIVED-${formatDate(pdp.archived_at, 'YYYY-MM-DD')}`
          : formatDate(pdp.created_at ?? pdp.created, 'YYYY-MM-DD')
      }-PDP-${pdp.owner?.staffGivenName}-${pdp.owner?.staffFamilyName}`;
    },
    setItems() {
      this.items = this.ownedPdp?.map((item) => ({
        text: this.displayPdpValue(item),
        value: item.id,
      }));

      if (!this.ownedPdp?.filter((pdp) => pdp.active)?.length > 0) {
        this.selected = null;
      }
    },
  },
};
</script>
