<template>
  <div>
    <Banner
      heading="Support & Feedback"
      subtitle="Support for Performance and Development"
      icon="mdi-help-circle-outline"
      defaultBackground1
    />
    <div class="container-wrapper">
      <v-tabs
        v-model="tab"
        class="ma-0 pa-0"
        slider-color="red"
      >
        <v-tab
          v-for="(item, index) in tabs"
          :key="`tab${index + 1}`"
        >
          {{ item.name }}
        </v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item key="tabItem1">
            <v-container
              class="container-main py-12 px-11"
              fluid
            >
              <div>
                <h2 class="title font-weight-bold mb-4 ads-primary-blue">
                  For more support
                </h2>
                <v-row class="mb-6">
                  <v-col
                    v-for="item in helpSupport"
                    :key="item.title"
                    md="6"
                    sm="12"
                  >
                    <v-card class="fill-height">
                      <div class="d-flex pa-5">
                        <div class="d-none d-md-block">
                          <v-img
                            width="96px"
                            height="96px"
                            alt="icon"
                            contain
                            src="../../../public/Warratah-onWhite.svg"
                          />
                        </div>
                        <div class="ml-5">
                          <h3
                            class="mb-3 title font-weight-bold ads-primary-blue"
                          >
                            {{ item.title }}
                          </h3>
                          <div
                            class="body-1 primary--text"
                            v-if="item.description"
                            v-html="item.description"
                          />

                          <v-list dense>
                            <v-list-item
                              class="px-0"
                              v-if="item.website_url_1"
                            >
                              <v-list-item-icon class="mr-2 mt-2">
                                <span class="d-sr-only">Website</span>
                                <v-icon
                                  aria-hidden="true"
                                  color="primary"
                                >
                                  public
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="py-0">
                                <a
                                  class="body-1 primary--text"
                                  target="_blank"
                                  :href="item.website_url_1"
                                >
                                  {{ item.website_text_1 }}
                                </a>
                              </v-list-item-content>
                            </v-list-item>

                            <div
                              class="body-1 primary--text"
                              v-if="item.web_2_description"
                              v-html="item.web_2_description"
                            />
                            <v-list-item
                              class="px-0"
                              v-if="item.website_url_2"
                            >
                              <v-list-item-icon class="mr-2 mt-2">
                                <span class="d-sr-only">Website</span>
                                <v-icon
                                  aria-hidden="true"
                                  color="primary"
                                >
                                  public
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="py-0">
                                <a
                                  class="body-1 primary--text"
                                  target="_blank"
                                  :href="item.website_url_2"
                                >
                                  {{ item.website_text_2 }}
                                </a>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item
                              class="px-0"
                              v-if="item.email"
                            >
                              <v-list-item-icon class="mr-2 my-1">
                                <span class="d-sr-only">Email address</span>
                                <v-icon
                                  aria-hidden="true"
                                  color="primary"
                                >
                                  alternate_email
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="py-0">
                                <a
                                  class="body-1 primary--text"
                                  :href="`mailto:${item.email}`"
                                >
                                  {{ item.email }}
                                </a>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item
                              v-if="item.phone"
                              class="px-0"
                            >
                              <v-list-item-icon class="mr-2 my-1">
                                <span class="d-sr-only">Phone number</span>
                                <v-icon
                                  aria-hidden="true"
                                  color="primary"
                                >
                                  phone_iphone
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="py-0">
                                <a
                                  class="body-1 primary--text"
                                  :href="`tel:${item.phone}`"
                                >
                                  {{ item.phone }}
                                </a>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </div>

              <div v-if="moreLinks">
                <h2 class="title font-weight-bold mb-4 ads-primary-blue">
                  {{ moreLinks.title }}
                </h2>

                <v-card class="px-8 py-10">
                  <v-row>
                    <v-col
                      v-for="item in moreLinks.items"
                      :key="item.text"
                      cols="12"
                      md="6"
                    >
                      <div>
                        <v-icon
                          aria-hidden="true"
                          color="primary"
                          class="mr-2"
                        >
                          mdi-logout-variant
                        </v-icon>
                        <a
                          class="primary--text"
                          target="_blank"
                          :href="item.url"
                        >
                          {{ item.text }}
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                  <h3 class="mt-8 mb-6 subtitle-1">Viva Engage</h3>
                  <v-row>
                    <v-col
                      v-for="item in vivaEngageLinks"
                      :key="item.text"
                      cols="12"
                      md="6"
                    >
                      <div>
                        <v-icon
                          aria-hidden="true"
                          color="primary"
                          class="mr-2"
                        >
                          mdi-logout-variant
                        </v-icon>
                        <a
                          class="primary--text"
                          target="_blank"
                          :href="item.url"
                        >
                          {{ item.text }}
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-container>
          </v-tab-item>
          <v-tab-item key="tabItem2">
            <v-container
              class="container-main py-12 px-11"
              fluid
            >
              <div>
                <h2 class="title font-weight-bold mb-4 ads-primary-blue">
                  Feedback Surveys
                </h2>
                <p class="mb-6 body-1">
                  We know your time is valuable and greatly appreciate your
                  feedback to help us continuously improve our tools, resources
                  and support around performance and development.
                </p>
                <v-row class="mb-6">
                  <v-col
                    v-for="item in feedbackCardsContent"
                    :key="item.title"
                    md="6"
                    sm="12"
                  >
                    <a
                      class="text-decoration-none"
                      :href="item.url"
                      target="_blank"
                    >
                      <v-card class="fill-height">
                        <div class="d-flex pa-5">
                          <div class="d-none d-md-block">
                            <v-img
                              width="96px"
                              height="96px"
                              alt="icon"
                              contain
                              src="../../../public/exchange-between-users.svg"
                            />
                          </div>
                          <div class="ml-5">
                            <h3
                              class="mb-3 title font-weight-bold ads-primary-blue"
                            >
                              {{ item.title }}
                            </h3>
                            <div
                              class="body-1"
                              v-if="item.description"
                              v-html="item.description"
                            />
                          </div>
                        </div>
                      </v-card>
                    </a>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { Banner } from '@nswdoe/doe-ui-core';

export default {
  name: 'Help',
  components: {
    Banner,
  },
  data() {
    return {
      tab: null,
      tabs: [{ name: 'Support' }, { name: 'Feedback' }],
      helpSupport: [
        {
          title: 'For Digital PDP Assistance',
          description:
            'Call EDConnect on 1300323232 – Option 3 > 7</br>Or log an EDConnect support ticket <a href="https://nswdoeesmp1.service-now.com/services_gateway?sys_id=1c4f8803337e861061bde6b45d5c7b2f&si=Digital%20PDP%20Support&id=sg_item" target="_blank" class="ads-secondary-blue">here</a>',
        },
      ],
      feedbackCardsContent: [
        {
          title: 'Digital PDP Feedback',
          description:
            'Have your say about the Digital PDP, including what you like and what you think could be improved',
          url: 'https://forms.office.com/Pages/DesignPageV2.aspx?origin=NeoPortalPage&subpage=design&id=muagBYpBwUecJZOHJhv5kQ47GN0ArdNEnpXiXKuY6qJUOUpCRllFSlFISTdWVkQ0OE5KVEdXQ1BWVyQlQCN0PWcu',
        },
      ],
      moreLinks: {
        title: 'Stay connected',
        items: [
          {
            text: 'Staff Noticeboard',
            url: 'https://education.nsw.gov.au/noticeboard',
          },
        ],
      },
      vivaEngageLinks: [
        {
          text: 'DoE SAMs and aspiring SAMs',
          url: 'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIzNzU1ODM2MjExMiJ9/new',
        },
        {
          text: 'DoE School Learning Support Officers (SLSO)',
          url: 'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIyMTcwOTA0NTc2In0/new',
        },
        {
          text: 'DoE School Administrative Officers',
          url: 'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIyMDgyMTM2MDY0In0/new',
        },
        {
          text: 'DoE General Assistants (GAs) and Farm Assistants (FAs)',
          url: 'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxNzQ2MDc2MyJ9/new',
        },
        {
          text: 'DoE Business Managers',
          url: 'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxNTY3MzY5MCJ9/new',
        },
        {
          text: 'DoE Aboriginal Education Officers',
          url: 'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIyMTczODI5MTIwIn0/all',
        },
        {
          text: 'DoE Student Support Officers ',
          url: 'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI2MTY5ODg2NzIwMCJ9/all',
        },
        {
          text: 'DoE Community Liaison Officers',
          url: 'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxNjI2MDM3OSJ9/all',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.container-wrapper,
.container-main {
  background-color: $ads-light-10;
}
:deep .banner > .col {
  padding-bottom: 24px;
}
:deep .v-tab {
  width: 200px;
}
</style>
