<template>
  <div>
    <UserBanner
      :user="loggedInUser"
      :pdp="pdp"
    />

    <v-container
      class="mt-n12 px-4 white"
      v-for="item in pdpGuideContent"
      :key="item.title"
    >
      <v-row class="pl-4">
        <v-col class="pl-0">
          <h2 class="display-1 my-3">
            {{ item.title }}
          </h2>
          <p class="body-1">
            {{ item.subtitle }}
          </p>
        </v-col>
      </v-row>
      <v-container
        v-for="cardContent in item.cards"
        :key="cardContent.number"
        class="mb-5"
      >
        <v-card class="pr-2 py-2">
          <v-container class="pl-2">
            <v-row class="my-2 pl-2">
              <v-col class="flex-grow-0">
                <FeatureIcon
                  size="40"
                  iconSize="18"
                  color="white"
                  backgroundColor="black"
                  icon="1"
                >
                  <template #icon>
                    {{ cardContent.number }}
                  </template>
                </FeatureIcon>
              </v-col>
              <v-col class="flex-grow-1 d-flex align-center">
                <h3 class="headline">
                  {{ cardContent.header }}
                </h3>
              </v-col>
            </v-row>
            <v-col>
              <p
                v-for="(subheader, index) in cardContent.subheaders"
                :key="index"
                class="body-1"
              >
                {{ subheader }}
              </p>
            </v-col>
            <v-col>
              <strong>
                <p class="subitle-1">
                  {{ cardContent.keyActivities.title }}
                </p>
              </strong>
              <ul
                v-for="listItem in cardContent.keyActivities.list"
                :key="listItem"
              >
                <li class="body-1">
                  {{ listItem }}
                </li>
              </ul>
            </v-col>
            <v-col>
              <strong>
                <p class="subitle-1">
                  {{ cardContent.phaseCheckpoint.title }}
                </p>
              </strong>
              <ul
                v-for="listItem in cardContent.phaseCheckpoint.list"
                :key="listItem"
              >
                <li class="body-1">
                  {{ listItem }}
                </li>
              </ul>
            </v-col>
          </v-container>
        </v-card>
      </v-container>
      <v-container />
    </v-container>
  </div>
</template>

<script>
import UserBanner from '../../components/UserBanner.vue';
import FeatureIcon from '@nswdoe/doe-ui-core/src/FeatureIcon/FeatureIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MobilePdpGuide',
  components: {
    UserBanner,
    FeatureIcon,
  },
  props: {
    pdpGuideContent: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['loggedInUser', 'pdp']),
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 959px) {
  :deep .row.banner .col {
    padding-top: 12px;
  }
}
</style>
