import { DialogState } from '../types';

export default {
  namespaced: true,
  state: {
    isOpen: false,
  } as DialogState,
  getters: {},
  mutations: {
    SET_DIALOG_STATUS(state: DialogState, isOpen: boolean) {
      state.isOpen = isOpen;
    },
  },
};
