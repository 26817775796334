<template>
  <v-card
    id="signaturepanel"
    class="pa-5 d-flex flex-row"
    :style="`border-top: 3px solid ${ADS_Colors.Blue_1_Dark}`"
  >
    <div>
      <v-icon :color="ADS_Colors.Blue_1_Dark">mdi-thumb-up-outline</v-icon>
    </div>
    <div
      class="d-flex flex-column ml-5 mr-5"
      style="width: 100%"
    >
      <p
        class="title"
        v-dompurify-html:sanitize="panelText.title"
      />
      <p
        class="ma-0 pa-0 body-1"
        v-dompurify-html:sanitize="panelText.text"
      ></p>
      <v-row
        class="mt-6 justify-space-between"
        dense
      >
        <v-col class="justify-space-between">
          <v-row>
            <v-col class="d-flex flex-column justify-space-between">
              <div class="d-flex flex-row">
                <InitialsIcon
                  size="48px"
                  :givenName="formatName(owner)"
                  class="mr-2"
                />
                <div>
                  <p
                    class="ma-0 pa-0"
                    v-dompurify-html:sanitize="`<b>${formatName(owner)}</b>`"
                  />

                  <p
                    class="mb-3"
                    v-dompurify-html:plaintext="
                      getPositions(owner.staffEmployment)
                    "
                  />
                </div>
              </div>
              <div>
                <v-btn
                  :class="`${
                    ownerButtonState?.action === SIGN_BUTTON_STATE.signed.action
                      ? 'disable-events'
                      : ''
                  } ${
                    ownerButtonState?.action ===
                      SIGN_BUTTON_STATE.signed.action ||
                    ownerButtonState?.action ===
                      SIGN_BUTTON_STATE.inactive.action ||
                    ownerButtonState?.action ===
                      SIGN_BUTTON_STATE.awaitReview.action ||
                    ownerButtonState?.action ===
                      SIGN_BUTTON_STATE.awaitSign.action
                      ? 'no-border'
                      : 'confirm-box'
                  } ${ownerTicked ? 'active-button' : ''} ${
                    this.hasAnyoneSigned && !this.hasOwnerSigned ? 'mb-6' : ''
                  }`"
                  :disabled="
                    ownerButtonState?.action ===
                      SIGN_BUTTON_STATE.inactive.action ||
                    ownerButtonState?.action ===
                      SIGN_BUTTON_STATE.awaitReview.action ||
                    ownerButtonState?.action ===
                      SIGN_BUTTON_STATE.awaitSign.action
                  "
                  depressed
                  min-width="192px"
                  @click="tickOwner"
                >
                  <v-checkbox
                    class="pr-2"
                    :input-value="ownerTicked"
                    readonly
                    v-if="
                      ownerButtonState?.action === SIGN_BUTTON_STATE.sign.action
                    "
                  />
                  <v-icon
                    v-if="
                      ownerButtonState?.action ===
                      SIGN_BUTTON_STATE.signed.action
                    "
                    color="green"
                    class="pr-5"
                  >
                    mdi-check-circle
                  </v-icon>
                  {{ ownerButtonState?.text }}
                </v-btn>
                <p class="mb-4 pt-2">
                  {{ formatDateTime(section?.userSignTime) }}
                </p>
              </div>
            </v-col>
            <v-col class="d-flex flex-column justify-space-between">
              <div class="d-flex flex-row">
                <InitialsIcon
                  size="48px"
                  :givenName="formatName(sectionSupervisor)"
                  class="mr-2"
                />
                <div>
                  <p
                    class="ma-0 pa-0"
                    v-dompurify-html:sanitize="
                      `<b>${formatName(sectionSupervisor)}</b>`
                    "
                  />
                  <p class="mb-3">PDP Supervisor</p>
                </div>
              </div>
              <div>
                <v-btn
                  :class="`${
                    supervisorButtonState?.action ===
                    SIGN_BUTTON_STATE.signed.action
                      ? 'disable-events'
                      : ''
                  } ${
                    supervisorButtonState?.action ===
                      SIGN_BUTTON_STATE.signed.action ||
                    supervisorButtonState?.action ===
                      SIGN_BUTTON_STATE.inactive.action ||
                    supervisorButtonState?.action ===
                      SIGN_BUTTON_STATE.awaitReview.action ||
                    supervisorButtonState?.action ===
                      SIGN_BUTTON_STATE.awaitSign.action
                      ? 'no-border'
                      : 'confirm-box'
                  } ${supervisorTicked ? 'active-button' : ''} ${
                    this.hasAnyoneSigned && !this.hasSupervisorSigned
                      ? 'mb-6'
                      : ''
                  }`"
                  :disabled="
                    supervisorButtonState?.action ===
                      SIGN_BUTTON_STATE.inactive.action ||
                    supervisorButtonState?.action ===
                      SIGN_BUTTON_STATE.awaitReview.action ||
                    supervisorButtonState?.action ===
                      SIGN_BUTTON_STATE.awaitSign.action
                  "
                  depressed
                  @click="tickSupervisor"
                  active-class=""
                  min-width="192px"
                >
                  <v-checkbox
                    class="pr-2"
                    :input-value="supervisorTicked"
                    readonly
                    v-if="
                      supervisorButtonState?.action ===
                      SIGN_BUTTON_STATE.sign.action
                    "
                  />
                  <v-icon
                    v-if="
                      supervisorButtonState?.action ===
                      SIGN_BUTTON_STATE.signed.action
                    "
                    color="green"
                    class="pr-5"
                  >
                    mdi-check-circle
                  </v-icon>
                  {{ supervisorButtonState?.text }}
                </v-btn>
                <p class="mb-4 pt-2">
                  {{ formatDateTime(section?.supervisorSignTime) }}
                </p>
              </div>
            </v-col>
            <v-col
              v-if="sectionPdpPrincipal"
              class="d-flex flex-column justify-space-between"
            >
              <div class="d-flex flex-row">
                <InitialsIcon
                  size="48px"
                  :givenName="formatName(sectionPdpPrincipal)"
                  class="mr-2"
                />
                <div>
                  <p
                    class="ma-0 pa-0"
                    v-dompurify-html:sanitize="
                      `<b>${formatName(sectionPdpPrincipal)}</b>`
                    "
                  />
                  <p class="mb-3">PDP Principal (optional)</p>
                </div>
              </div>
              <div>
                <v-btn
                  :class="`${
                    pdpPrincipalButtonState?.action ===
                    SIGN_BUTTON_STATE.signed.action
                      ? 'disable-events'
                      : ''
                  } ${
                    pdpPrincipalButtonState?.action ===
                      SIGN_BUTTON_STATE.signed.action ||
                    pdpPrincipalButtonState?.action ===
                      SIGN_BUTTON_STATE.inactive.action ||
                    pdpPrincipalButtonState?.action ===
                      SIGN_BUTTON_STATE.awaitReview.action ||
                    pdpPrincipalButtonState?.action ===
                      SIGN_BUTTON_STATE.awaitSign.action
                      ? 'no-border'
                      : 'confirm-box'
                  } ${pdpPrincipalTicked ? 'active-button' : ''} ${
                    this.hasAnyoneSigned && !this.hasPdpPrincipalSigned
                      ? 'mb-6'
                      : ''
                  }`"
                  :disabled="
                    pdpPrincipalButtonState?.action ===
                      SIGN_BUTTON_STATE.inactive.action ||
                    pdpPrincipalButtonState?.action ===
                      SIGN_BUTTON_STATE.awaitReview.action ||
                    pdpPrincipalButtonState?.action ===
                      SIGN_BUTTON_STATE.awaitSign.action
                  "
                  depressed
                  min-width="192px"
                  @click="tickPdpPrincipal"
                >
                  <v-checkbox
                    class="pr-2"
                    :input-value="pdpPrincipalTicked"
                    readonly
                    v-if="
                      pdpPrincipalButtonState?.action ===
                      SIGN_BUTTON_STATE.sign.action
                    "
                  />
                  <v-icon
                    v-if="
                      pdpPrincipalButtonState?.action ===
                      SIGN_BUTTON_STATE.signed.action
                    "
                    color="green"
                    class="pr-5"
                  >
                    mdi-check-circle
                  </v-icon>
                  {{ pdpPrincipalButtonState?.text }}
                </v-btn>
                <p class="mb-4 pt-2">
                  {{ formatDateTime(section?.principalSignTime) }}
                </p>
              </div>
            </v-col>
            <v-col
              class="d-flex align-end"
              cols="2"
            >
              <AdsButton
                v-if="confirmButtonEnabled"
                :disabled="!confirmButtonEnabled"
                icon="mdi-send-outline"
                buttonText="Confirm"
                @click="confirmAction"
                style="height: 52px"
                depressed
                :class="`${
                  section?.userSignTime ||
                  section?.supervisorSignTime ||
                  section?.principalSignTime
                    ? 'mb-12'
                    : 'mb-6'
                } ${!confirmButtonEnabled ? 'confirm-btn--disabled' : ''}`"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { formatDateTime, formatName, getPositions } from '@/utils';
import { findPdpUserByType } from '@/lib/pdp';
import { mapGetters } from 'vuex';
import InitialsIcon from '@nswdoe/doe-ui-core/src/InitialsIcon';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { AdsButton } from '@nswdoe/doe-ui-core';
import { SECTION_TYPE, SIGN_BUTTON_STATE, USER_TYPE } from '@/data/constants';

export default {
  name: 'SignaturePanel',
  components: { InitialsIcon, AdsButton },
  props: {
    owner: {
      type: Object,
      default: null,
    },
    users: {
      type: Array,
      default: null,
    },
    section: {
      type: Object,
      default: null,
    },
    disableSign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ADS_Colors,
      SIGN_BUTTON_STATE,
      ownerTicked: false,
      supervisorTicked: false,
      pdpPrincipalTicked: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    sectionSupervisor() {
      if (this.section?.supervisor_id) {
        return this.users?.find(
          (user) => user.user_id === this.section?.supervisor_id
        )?.user;
      } else {
        return findPdpUserByType(this.users, USER_TYPE.supervisor)?.user;
      }
    },
    sectionPdpPrincipal() {
      if (this.section?.principal_id) {
        return this.users?.find(
          (user) => user.user_id === this.section?.principal_id
        )?.user;
      } else {
        return findPdpUserByType(this.users, USER_TYPE.pdpPrincipal)?.user;
      }
    },
    isOwner() {
      return this.loggedInUser?.id === this.owner?.id;
    },
    isSupervisor() {
      return this.loggedInUser?.id === this.sectionSupervisor?.id;
    },
    isPdpPrincipal() {
      return this.loggedInUser?.id === this.sectionPdpPrincipal?.id;
    },
    hasOwnerSigned() {
      return !!this.section?.userSignTime && !!this.section?.userSignature;
    },
    hasSupervisorSigned() {
      return (
        !!this.section?.supervisorSignTime &&
        !!this.section?.supervisorSignature
      );
    },
    hasPdpPrincipalSigned() {
      return (
        !!this.section?.principalSignTime && !!this.section?.principalSignature
      );
    },
    hasAnyoneSigned() {
      return (
        this.hasOwnerSigned ||
        this.hasSupervisorSigned ||
        this.hasPdpPrincipalSigned
      );
    },
    ownerButtonState() {
      return this.getButtonState(
        USER_TYPE.owner,
        this.isOwner,
        this.hasOwnerSigned,
        this.section?.notifySupervisor
      );
    },
    supervisorButtonState() {
      return this.getButtonState(
        USER_TYPE.supervisor,
        this.isSupervisor,
        this.hasSupervisorSigned,
        this.section?.notifySupervisor
      );
    },
    pdpPrincipalButtonState() {
      return this.getButtonState(
        USER_TYPE.pdpPrincipal,
        this.isPdpPrincipal,
        this.hasPdpPrincipalSigned,
        this.section?.notifySupervisor
      );
    },
    confirmButtonVisible() {
      return (
        (this.isOwner &&
          this.section.notifySupervisor &&
          !this.section.userSignature) ||
        (this.isSupervisor &&
          this.section.notifySupervisor &&
          !this.section.supervisorSignature) ||
        (this.isPdpPrincipal &&
          this.section.notifySupervisor &&
          !this.section.principalSignature)
      );
    },
    confirmButtonEnabled() {
      return (
        (this.isOwner &&
          this.ownerTicked &&
          this.section.notifySupervisor &&
          !this.section.userSignature) ||
        (this.isSupervisor &&
          this.supervisorTicked &&
          this.section.notifySupervisor &&
          !this.section.supervisorSignature) ||
        (this.isPdpPrincipal &&
          this.pdpPrincipalTicked &&
          this.section.notifySupervisor &&
          !this.section.principalSignature)
      );
    },
    panelText() {
      if (this.isOwner) {
        if (this.section.sectionType === SECTION_TYPE.plan) {
          return {
            title: 'Complete your plan',
            text: 'Meet with your PDP supervisor to complete your plan.<br/>Both you and your PDP supervisor must sign your plan to proceed to the <b>Implement</b> phase.<br/>You are required to sign again if any change to the plan is made.</br>To sign, select <b>Sign</b>, then select <b>Confirm</b>.',
          };
        }
        if (this.section.sectionType === SECTION_TYPE.midYear) {
          return {
            title: 'Complete your mid-year self-assessment',
            text: 'Meet with your PDP supervisor to complete your mid-year self-assessment.<br/>Both you and your PDP supervisor must sign your mid-year self-assessment to proceed to the <b>Review</b> phase.<br/>You are required to sign again if any change to your mid-year self-assessment is made.<br/>To sign, select <b>Sign</b> below, then select <b>Confirm</b>.',
          };
        }
        if (this.section.sectionType === SECTION_TYPE.final) {
          return {
            title: 'Complete your annual review',
            text: 'Meet with your PDP supervisor to complete your annual review.</br>Both you and your PDP supervisor must sign your annual review to complete your PDP.</br>You are required to sign again if any change to your annual review is made.</br>To sign, select <b>Sign</b> below, then select <b>Confirm</b>.',
          };
        }
      } else if (this.isSupervisor) {
        if (this.section.sectionType === SECTION_TYPE.plan) {
          return {
            title: `Meet with ${this.formatName(
              this.owner
            )} and sign their plan`,
            text: `Once you have met with ${this.formatName(
              this.owner
            )} and are ready to sign their plan, select <b>Sign</b> then select <b>Confirm</b>.<br/>Once you have both signed, ${this.formatName(
              this.owner
            )}'s PDP will proceed to the <b>Implement</b> phase.<br/>You are required to sign again if any change to the plan is made.`,
          };
        }
        if (this.section.sectionType === SECTION_TYPE.midYear) {
          return {
            title: `Meet with ${this.formatName(
              this.owner
            )} and sign their mid-year self-assessment`,
            text: `Once you have met with ${this.formatName(
              this.owner
            )} and are ready to sign their mid-year self-assessment, select <b>Sign</b>, then select <b>Confirm</b> to proceed.<br/>Once you have both signed, ${this.formatName(
              this.owner
            )}'s PDP will proceed to the <b>Review</b> phase.<br/>You are required to sign again if any change to the mid-year self-assessment is made.`,
          };
        }
        if (this.section.sectionType === SECTION_TYPE.final) {
          return {
            title: `Meet with ${this.formatName(
              this.owner
            )}, comment and sign their annual review.`,
            text: `Once you have met with ${this.formatName(
              this.owner
            )}, enter your comments in the PDP supervisor box below.<br/>Select <b>sign</b> and then select <b>confirm</b>.<br/>Once you have both signed ${this.formatName(
              this.owner
            )}'s annual review, the PDP will be completed.<br/>You are required to sign again if any change is made to the annual review.`,
          };
        }
      } else if (this.isPdpPrincipal) {
        if (this.section.sectionType === SECTION_TYPE.plan) {
          return {
            title: `View and sign ${this.formatName(this.owner)}'s PDP`,
            text: 'Select <b>Sign</b>, then select <b>Confirm</b> to proceed.',
          };
        }
        if (this.section.sectionType === SECTION_TYPE.midYear) {
          return {
            title: `View and sign ${this.formatName(
              this.owner
            )}'s mid-year self-assessment`,
            text: 'Select <b>Sign</b>, then select <b>Confirm</b> to proceed.',
          };
        }
        if (this.section.sectionType === SECTION_TYPE.final) {
          return {
            title: `View and sign ${this.formatName(
              this.owner
            )}'s annual review`,
            text: 'Select <b>Sign</b>, then select <b>Confirm</b> to proceed.',
          };
        }
      }
      return { title: '', text: '' };
    },
  },
  methods: {
    formatDateTime,
    getPositions,
    formatName,
    tickOwner() {
      this.ownerTicked = !this.ownerTicked;
    },
    tickSupervisor() {
      this.supervisorTicked = !this.supervisorTicked;
    },
    tickPdpPrincipal() {
      this.pdpPrincipalTicked = !this.pdpPrincipalTicked;
    },
    getButtonState(role, isRole, isSigned, notifySupervisor) {
      let result = SIGN_BUTTON_STATE.default;
      switch (true) {
        case isSigned && notifySupervisor:
          result = SIGN_BUTTON_STATE.signed;
          break;
        case this.disableSign:
          result = SIGN_BUTTON_STATE.awaitReview;
          break;
        case !notifySupervisor:
          result = SIGN_BUTTON_STATE.inactive;
          break;
        case isRole && !isSigned && notifySupervisor:
          if (role === USER_TYPE.pdpPrincipal) {
            if (this.hasOwnerSigned && this.hasSupervisorSigned) {
              result = SIGN_BUTTON_STATE.sign;
            } else {
              result = SIGN_BUTTON_STATE.pending;
            }
          } else if (
            // Supervisee and Supervisor can only sign the final section after both have made their mandatory comments
            this.section?.sectionType === SECTION_TYPE.final &&
            !this.section?.supervisorComment
          ) {
            result = SIGN_BUTTON_STATE.awaitSign;
          } else {
            result = SIGN_BUTTON_STATE.sign;
          }
          break;
        case !isSigned && notifySupervisor:
          if (role === USER_TYPE.pdpPrincipal) {
            result = SIGN_BUTTON_STATE.pending;
          } else {
            result = SIGN_BUTTON_STATE.awaitSign;
          }
          break;
      }
      return result;
    },
    confirmAction() {
      let userType = '';
      switch (true) {
        case this.isOwner && this.ownerTicked:
          userType = USER_TYPE.owner;
          this.ownerTicked = false;
          break;
        case this.isSupervisor && this.supervisorTicked:
          userType = USER_TYPE.supervisor;
          this.supervisorTicked = false;
          break;
        case this.isPdpPrincipal && this.pdpPrincipalTicked:
          userType = USER_TYPE.pdpPrincipal;
          this.pdpPrincipalTicked = false;
          break;
        default:
          break;
      }
      this.$emit('signed', this.section, userType);
    },
  },
  emits: ['signed'],
};
</script>

<style scoped lang="scss">
:deep .confirm-box {
  background-color: white !important;
  border: $ads-navy-dark-1 solid 2px !important;
}
.disable-events {
  pointer-events: none;
}
:deep .no-border {
  border: none;
  background-color: $ads-grey-04 !important;

  &.v-btn--disabled.v-btn--has-bg {
    background-color: $ads-grey-04 !important;

    .v-btn__content {
      color: $ads-grey-02;
      font-weight: 400;
    }
  }
}
:deep .v-btn--disabled.v-btn--has-bg.confirm-btn--disabled {
  background-color: $ads-grey-02 !important;

  .v-btn__content {
    color: $ads-white;

    .v-icon {
      color: $ads-white !important;
    }
  }
}
.active-button {
  background-color: $ads-tertiary-blue-dark !important;
}
</style>
