<template>
  <v-banner color="white">
    <div class="d-flex flex-row justify-space-between">
      <PdpSelect
        :userType="userType"
        style="width: 30%"
        @selected="pdpSelected"
      />
      <v-menu
        v-if="showMoreOptions"
        offset-y
      >
        <template #activator="{ on, attrs }">
          <AdsButton
            icon="mdi-dots-vertical"
            buttonText="More actions"
            v-bind="attrs"
            v-on="on"
            tertiary
          ></AdsButton>
        </template>
        <v-list>
          <v-list-item>
            <AdsButton
              buttonText="Print PDP"
              tertiary
              icon="mdi-printer-outline"
              @click="handlePrintPDP"
            />
          </v-list-item>
          <v-list-item v-if="pdp.active && userType === USER_TYPE.owner">
            <AdsButton
              buttonText="Archive PDP"
              tertiary
              icon="mdi-archive-arrow-down-outline"
              @click="handleShowArchiveDialog"
            />
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <FullscreenDialog
      :title="`${pdpOwnerName}'s PDP`"
      v-model="showDialog"
      contentClass="nts-print-content"
    >
      <template #action>
        <AdsButton
          buttonText="Print"
          @click="printPage"
        />
      </template>
      <template #content>
        <!-- Using table as a hack to display custom timestamp at the top of every printed page -->
        <table>
          <thead>
            <v-toolbar flat>
              <v-toolbar-title class="body-1">
                {{ userDateStamp }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <div class="subtitle-1">
                {{ printedDateTime }}
              </div>
            </v-toolbar>
          </thead>
          <slot
            v-if="showDialog"
            name="printContent"
          ></slot>
        </table>
      </template>
    </FullscreenDialog>
    <Dialog
      displayCloseBtn
      :openDialog="showArchiveDialog"
      :return-handler="handleShowArchiveDialog"
      @close="handleShowArchiveDialog"
      maxWidth="45em"
      v-if="showArchiveDialog"
    >
      <template #text>
        <ArchivePdpModal
          class="px-12"
          @close="handleShowArchiveDialog"
          @save="handleArchive"
        />
      </template>
    </Dialog>
  </v-banner>
</template>

<script>
import { AdsButton, FullscreenDialog, Dialog } from '@nswdoe/doe-ui-core';
import { formatDateTime, formatName, formatDate } from '@/utils';
import { mapMutations, mapGetters } from 'vuex';
import PdpSelect from '@/components/PdpSelect.vue';
import { USER_TYPE, PDP_ROUTES } from '@/data/constants';
import { findPdpUserByType } from '@/lib/pdp';
import ArchivePdpModal from '@/components/ArchivePdpModal.vue';
export default {
  name: 'Ribbon',
  components: {
    ArchivePdpModal,
    PdpSelect,
    AdsButton,
    FullscreenDialog,
    Dialog,
  },
  props: {
    valid: {
      type: Boolean,
      default: false,
    },
    pdp: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      USER_TYPE,
      showDialog: false,
      showArchiveDialog: false,
      userDateStamp: '',
      printedDateTime: '',
    };
  },
  computed: {
    ...mapGetters(['formPdp', 'actionPdp', 'loggedInUser']),
    isMobile() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    pdpOwnerName() {
      return formatName(this.pdp?.owner);
    },
    currentDate() {
      const currentDate = new Date();
      return `Performance and Development Plan - ${
        this.pdpOwnerName
      } - ${currentDate.toLocaleDateString('en-AU', {
        year: 'numeric',
        day: '2-digit',
        month: '2-digit',
      })}`;
    },
    userType() {
      switch (this.$route.name) {
        case PDP_ROUTES.myPdp.base.name:
        case PDP_ROUTES.myPdp.pdpId.name:
        case PDP_ROUTES.myPdp.selectedTab.name:
          return USER_TYPE.owner;
        case PDP_ROUTES.management.base.name:
        case PDP_ROUTES.management.pdpId.name:
        case PDP_ROUTES.management.selectedTab.name:
        case PDP_ROUTES.management.actionTab.name:
          return USER_TYPE.supervisor;
        default:
          return null;
      }
    },
    showMoreOptions() {
      switch (this.userType) {
        case USER_TYPE.owner: {
          return !!this.formPdp;
        }
        case USER_TYPE.supervisor: {
          return !!this.actionPdp;
        }
        default: {
          return false;
        }
      }
    },
  },
  methods: {
    ...mapMutations('dialog', ['SET_DIALOG_STATUS']),
    pdpSelected(id) {
      this.$emit('selected', id);
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    printPage() {
      const currentDate = new Date();
      const originalTitle = document.title;
      const userDateStamp =
        this.generateDateStamp(currentDate) +
        `-PDP-${this.pdpOwnerName.replace(' ', '-')}`;
      document.title = userDateStamp;
      this.printedDateTime =
        'Printed ' + this.formatPrintedDateTime(currentDate);
      window.print();
      document.title = originalTitle;
    },
    generateDateStamp(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    formatPrintedDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      const amOrPm = hours < 12 ? 'am' : 'pm';

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      return `${day}/${month}/${year} ${formattedHours}:${minutes}${amOrPm}`;
    },
    handlePrintPDP() {
      const currentDate = new Date();
      if (this.pdp?.active) {
        this.userDateStamp = this.generateDateStamp(currentDate);
      } else {
        this.userDateStamp = `ARCHIVED-${formatDate(
          this.pdp?.archived_at,
          'YYYY-MM-DD'
        )}`;
      }
      this.userDateStamp =
        this.userDateStamp + `-PDP-${this.pdpOwnerName.replace(' ', '.')}`;
      this.printedDateTime =
        'Printed ' + this.formatPrintedDateTime(currentDate);
      this.$emit('print');
      this.showDialog = true;
    },
    handleShowArchiveDialog() {
      this.showArchiveDialog = !this.showArchiveDialog;
    },
    handleArchive() {
      this.$emit('save');
      this.handleShowArchiveDialog();
    },
    isLoggedInUserOfType(userType) {
      const user = findPdpUserByType(
        this.actionPdp?.pdp_user_associations,
        userType
      );
      return user?.user_id === this.loggedInUser?.id;
    },
  },
  watch: {
    showDialog(value) {
      this.SET_DIALOG_STATUS(value);
    },
  },
};
</script>

<style scoped lang="scss">
:deep .v-banner__wrapper {
  border-bottom: none !important;
}

:deep .v-alert__content button {
  pointer-events: none;
}
:deep .v-dialog.v-dialog__content--active {
  .v-btn:not(header .v-btn) {
    pointer-events: none;
  }
}
:deep button.v-expansion-panel-header {
  pointer-events: none;
}
.page-break {
  break-after: page;
}
.v-tabs {
  pointer-events: none;
}
:deep .nts-user-banner--print {
  &.banner1 {
    color: $ads-grey-01;
    :deep .v-icon {
      color: $ads-grey-01;
    }
  }
  button {
    display: none;
  }
  .v-avatar {
    background-color: $ads-white;
    margin-left: 0 !important;
  }
  .initials-icon,
  .appTitle,
  .user-role__text {
    color: $ads-grey-01;
  }
  .supervisors-box {
    &__card {
      background-color: $ads-white;
      margin-right: 0 !important;
    }
    &__text {
      color: $ads-grey-01;
    }
  }
  .user-banner.container {
    padding: 0;
  }
}
table {
  width: 100%;
  border-collapse: collapse;
}
:deep tbody {
  word-break: break-word;
}
.nts-print-content {
  :deep button {
    pointer-events: none;
  }
}
:deep .goal-buttons,
:deep .nts-add-goal-button,
:deep .save-learning-and-development-button,
:deep .save-annual-review-btn,
thead {
  display: none;
}
:deep .ql-editor {
  min-height: auto;
}
:deep .right-panel {
  margin-right: 0 !important;
  background-color: $ads-white;
  color: $ads-grey-01;
}
:deep .chip__side-text {
  color: $ads-grey-01;
}

@media print {
  thead {
    display: table-header-group;
  }
  .container.nts-user-banner--print {
    padding: 0 !important;
  }
}
</style>
