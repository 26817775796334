<template>
  <div class="notes-bar">
    <v-container>
      <v-row justify="space-between">
        <v-col>
          <v-icon>edit</v-icon>
          <strong>Your personal notes</strong>
          <br />
          Use notes to prepare for meetings.
        </v-col>
        <v-spacer />
        <v-col class="text-right">
          <FullscreenDialog
            title="Notes"
            v-model="openDialog"
          >
            <template #activator>
              <AdsButton
                :button-text="displayedButtonText"
                @click="handleOpen"
              />
            </template>
            <template #action>
              <AdsButton
                :disabled="imageError"
                :button-text="edit ? 'Save &amp; Close' : 'Close'"
                @click="handleUpdate"
              />
            </template>
            <template #content>
              <div class="editor-wrapper">
                <Alert
                  allowDismiss
                  showAlert
                  type="info"
                  :text="
                    archived
                      ? 'Your archived personal notes'
                      : 'Your personal notes'
                  "
                  inPage
                  class="flex-grow-1 my-4"
                  alertClass="info-background-banner"
                >
                  <template #optional>
                    <div v-if="archived">
                      Personal notes can only be viewed by you. They will be
                      printed on a separate page at the end of the document.
                    </div>
                    <div v-else>
                      Personal notes can only be viewed by you. They will be
                      printed on a separate page at the end of the document and
                      will be saved as part of the archived PDP.
                    </div>
                  </template>
                </Alert>
                <div
                  v-if="imageError"
                  class="d-flex pa-5 justify-start error--text"
                >
                  <v-icon
                    aria-hidden="true"
                    class="error--text warning-icon pr-2"
                  >
                    error
                  </v-icon>
                  Sorry, you cannot insert images into Notes. Please delete
                  images to save your Notes.
                </div>
                <v-card
                  v-if="!edit"
                  class="pa-10 mb-0 body-1 black--text"
                  title="Card title"
                  v-dompurify-html="notes ?? 'No notes'"
                />
                <VueEditor
                  v-else-if="edit"
                  ref="vue-editor"
                  :editorToolbar="customToolbar"
                  v-model="notes"
                  class="editor"
                  onpaste="return !!event.clipboardData.getData('text');"
                />
              </div>
            </template>
          </FullscreenDialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FullscreenDialog from '@nswdoe/doe-ui-core/src/FullscreenDialog/FullscreenDialog.vue';
import AdsButton from '@nswdoe/doe-ui-core/src/AdsButton/AdsButton.vue';
import Alert from '@nswdoe/doe-ui-core/src/Alert/Alert.vue';
import { VueEditor, Quill } from 'vue2-editor';

export default {
  name: 'NotesBar',
  components: {
    FullscreenDialog,
    AdsButton,
    VueEditor,
    Alert,
  },
  props: {
    userType: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    archived: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openDialog: false,
      imageError: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }], // dropdown with defaults from theme
        ['link'],
        ['clean'], // remove formatting button
        // For all toolbars options
        // https://github.com/davidroyer/vue2-editor/blob/master/src/helpers/fullToolbar.js
      ],
      notes: '',
    };
  },
  computed: {
    displayedButtonText() {
      return this.edit ? 'Add and view notes' : 'View notes';
    },
  },
  watch: {
    open: {
      handler() {
        this.openDialog = this.open;
      },
    },
    openDialog: {
      handler() {
        if (this.openDialog) {
          this.notes = this.value;
          // Vue2Editor is built upon Quill, so Quill's APIs can be used
          // prevents pasting images into quill container (https://github.com/quilljs/quill/issues/1108#issuecomment-757547630)
          this.$nextTick(() => {
            if (this.$refs['vue-editor']) {
              this.$refs['vue-editor'].quill.clipboard.addMatcher('IMG', () => {
                const Delta = Quill.import('delta');
                return new Delta().insert('');
              });
              this.$refs['vue-editor'].quill.clipboard.addMatcher(
                'PICTURE',
                () => {
                  const Delta = Quill.import('delta');
                  return new Delta().insert('');
                }
              );
            }
          });
        } else {
          this.handleClose();
        }
      },
      immediate: true,
    },
    notes: {
      handler() {
        if (this.notes) {
          this.handleInput();
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleOpen() {
      this.$emit('openNotes');
    },
    handleUpdate() {
      if (this.edit) {
        this.$emit('updateNotes');
      } else {
        this.handleClose();
      }
    },
    handleClose() {
      this.$emit('closeNotes');
    },
    handleInput() {
      this.$emit('input', this.notes);
    },
  },
};
</script>

<style lang="scss" scoped>
.notes-bar {
  width: 100%;
  background: white;
}

.editor-wrapper {
  width: 100%;
  padding: 2em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.editor {
  width: 100%;
  height: 100%;
}

.info-background-banner {
  /* background-color: $ads-primary-teal-dark !important; */
  border: none !important;
}
</style>
