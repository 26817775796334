<template>
  <MobilePdpGuide
    v-if="$vuetify.breakpoint.smAndDown"
    :pdpGuideContent="pdpGuideContent"
  />
  <DesktopPdpGuide
    v-else
    :pdpGuideContent="pdpGuideContent"
  />
</template>

<script>
import MobilePdpGuide from './MobilePdpGuide.vue';
import DesktopPdpGuide from './DesktopPdpGuide.vue';
import { overview, NTSoverview } from '../../data/overview.js';
import { mapGetters } from 'vuex';

export default {
  name: 'PdpGuide',
  components: {
    DesktopPdpGuide,
    MobilePdpGuide,
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    pdpGuideContent() {
      return this.loggedInUser?.isNonTeachingStaff ? NTSoverview : overview;
    },
  },
};
</script>
