<template>
  <tbody>
    <TSUserBannerPrint
      class="ts-user-banner--print"
      backgroundColour="white"
      :pdp="actionPdp"
      :user="actionPdp?.owner"
    />
    <!-- PLAN SECTION -->
    <v-container class="px-0">
      <PrintTabs
        :tabs="tabs"
        :selectedTab="getTabIndexById(TAB_TYPES.plan.id)"
      />
    </v-container>
    <div v-if="canViewSection(TAB_TYPES.plan)">
      <OrientationBanner
        v-if="isCurrentSupervisor && !showTabs"
        isSupervisor
        :pdp="actionPdp ?? {}"
        :section="activeSection"
        :supervisorName="supervisorName"
        :isSigningPrintBanner="isSigningPrintBanner"
        readOnly
        class="pa-0 mt-3"
      />
      <TSPlanReadOnly
        :pdp="actionPdp"
        :section="planSection"
      />
    </div>
    <v-container
      v-else
      class="px-0"
    >
      <NoPermissionCard />
    </v-container>
    <!-- GOALS AND EVIDENCE -->
    <v-container class="px-0 page-break-before">
      <PrintTabs
        :tabs="tabs"
        :selectedTab="getTabIndexById(TAB_TYPES.goalsAndEvidence.id)"
      />
    </v-container>
    <v-container
      v-if="canViewSection(TAB_TYPES.goalsAndEvidence)"
      class="pa-0"
    >
      <v-card class="my-5">
        <v-container
          class="px-0 px-md-10 py-5"
          fluid
        >
          <TSPdpGoalsPrint
            :editable="false"
            isSupervisor
            :owner="actionPdp?.owner"
            :section="midYearSection"
            :showEditToggle="false"
            :startEdit="false"
            :userIsPrincipal="ownerIsPrincipal"
            :goals="midYearSection.goals"
            expandAll
          />
        </v-container>
      </v-card>
    </v-container>
    <v-container
      v-else
      class="px-0"
    >
      <NoPermissionCard />
    </v-container>

    <!-- OBSERVATIONS -->
    <v-container class="px-0 page-break-before">
      <PrintTabs
        :tabs="tabs"
        :selectedTab="getTabIndexById(TAB_TYPES.observations.id)"
      />
      <v-card
        v-if="canViewSection(TAB_TYPES.observations)"
        class="my-5 py-5 page-break"
      >
        <TSPdpObservationsPrint
          :pdp="actionPdp"
          :editMode="false"
        />
      </v-card>
      <NoPermissionCard
        v-else
        class="px-0"
      />
    </v-container>
    <!-- REVIEWS -->
    <v-container class="pa-0 page-break-before">
      <PrintTabs
        :tabs="tabs"
        :selectedTab="getTabIndexById(TAB_TYPES.reviews.id)"
      />
      <div v-if="canViewSection(TAB_TYPES.reviews)">
        <OrientationBanner
          v-if="
            planSection.complete &&
            (isCurrentSupervisor || finalSection.complete)
          "
          isSupervisor
          :pdp="actionPdp ?? {}"
          :section="activeSection"
          :supervisorName="supervisorName"
          :isSigningPrintBanner="isSigningPrintBanner"
          readOnly
          class="pa-0 mt-3"
        />
        <Wrapper
          :isMobile="isMobile"
          cardClass="py-5 px-10"
          class="mt-8 pa-0"
        >
          <h4 class="display-1 pt-5 mb-5 mx-5 mx-md-0">Reviews</h4>
          <AdsExpansionPanel
            v-model="reviewPanels"
            :items="[
              { id: 1, title: 'Mid-Year Self-Assessment' },
              { id: 2, title: 'Annual review' },
            ]"
            multiple
          >
            <template #[`content1`]>
              <v-container class="pa-0 pa-md-5">
                <v-card class="pa-5">
                  <v-card-title class="px-0 px-md-4 text-h6">
                    Review of progress
                  </v-card-title>
                  <div
                    class="px-0 px-md-4 text-body-1"
                    v-dompurify-html="
                      actionPdp.midYearText ? actionPdp.midYearText : ''
                    "
                  />
                  <p class="title mt-5">
                    <v-icon
                      :color="ADS_Colors.Blue_2"
                      class="mr-2"
                    >
                      mdi-message-reply-text-outline
                    </v-icon>
                    Comments
                  </p>
                  <v-row class="mx-n5 mx-md-n3 flex-column">
                    <v-col class="px-0 px-md-3">
                      <v-card
                        v-if="midYearSection?.supervisorSignature"
                        class="elevation-4 mx-0 mx-md-5 mt-2"
                      >
                        <div
                          class="px-5 pt-5 px-md-4 text-body-1"
                          v-dompurify-html="
                            midYearSection.supervisorComment
                              ? midYearSection.supervisorComment
                              : '<p>No comments from PDP supervisor</p>'
                          "
                        />
                        <v-card-title
                          v-if="midYearSection?.supervisorSignature"
                          class="px-5 px-md-4 text-h6"
                        >
                          PDP Supervisor
                          {{ formatName(midYearSupervisor) }}
                          signed this section on
                          {{
                            formatDateTime(midYearSection.supervisorSignTime)
                          }}
                        </v-card-title>
                      </v-card>
                    </v-col>
                    <v-col class="px-0 px-md-3">
                      <v-card
                        v-if="midYearSection?.userSignature"
                        class="elevation-4 mx-0 mx-md-5 mt-2"
                      >
                        <div
                          class="px-5 pt-5 px-md-4 text-body-1"
                          v-dompurify-html="
                            midYearSection.userComment
                              ? midYearSection.userComment
                              : '<p>No comments from supervisee</p>'
                          "
                        />
                        <v-card-title
                          v-if="midYearSection.userSignTime"
                          class="px-5 px-md-4 text-h6"
                        >
                          {{ formatName(actionPdp?.owner) }}
                          signed this section on
                          {{ formatDateTime(midYearSection.userSignTime) }}
                        </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-container>
              <v-card
                v-if="!midYearSection.complete"
                class="pa-5"
                flat
              >
                <p class="ml-0 ml-md-5">
                  <strong>Mid Year Self-Assessment</strong>
                  has not yet been completed
                </p>
              </v-card>
            </template>
            <template #[`content2`]>
              <div v-if="isCurrentSupervisor || userIsSchoolPrincipal">
                <v-container class="pa-0 pa-md-5">
                  <v-card class="pa-5">
                    <v-card-title class="px-0 px-md-4 text-h6">
                      Annual review
                    </v-card-title>
                    <div
                      class="px-0 px-md-4 text-body-1"
                      v-dompurify-html="
                        actionPdp.annualText ? actionPdp.annualText : ''
                      "
                    />
                    <p class="title mt-5">
                      <v-icon
                        :color="ADS_Colors.Blue_2"
                        class="mr-2"
                      >
                        mdi-message-reply-text-outline
                      </v-icon>
                      Comments
                    </p>
                    <v-row class="mx-n5 mx-md-n3 flex-column">
                      <v-col class="px-0 px-md-3">
                        <v-card
                          v-if="finalSection?.supervisorSignature"
                          class="elevation-4 mx-0 mx-md-5 mt-2"
                        >
                          <div
                            class="px-5 pt-5 px-md-4 text-body-1"
                            v-dompurify-html="
                              finalSection?.supervisorComment
                                ? finalSection?.supervisorComment
                                : '<p>No comments from PDP supervisor</p>'
                            "
                          />
                          <v-card-title
                            v-if="finalSection?.supervisorSignTime"
                            class="px-5 px-md-4 text-h6"
                          >
                            PDP Supervisor
                            {{ formatName(finalSupervisor) }}
                            signed this section on
                            {{
                              formatDateTime(finalSection?.supervisorSignTime)
                            }}
                          </v-card-title>
                        </v-card>
                      </v-col>
                      <v-col class="px-0 px-md-3">
                        <v-card
                          v-if="finalSection?.userSignature"
                          class="elevation-4 mx-0 mx-md-5 mt-2"
                        >
                          <div
                            class="px-5 pt-5 px-md-4 text-body-1"
                            v-dompurify-html="
                              finalSection?.userComment
                                ? finalSection?.userComment
                                : '<p>No comments from supervisee</p>'
                            "
                          />
                          <v-card-title
                            v-if="finalSection?.userSignTime"
                            class="px-5 px-md-4 text-h6"
                          >
                            {{ formatName(actionPdp?.owner) }}
                            signed this section on
                            {{ formatDateTime(finalSection?.userSignTime) }}
                          </v-card-title>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-container>
                <v-card
                  v-if="!finalSection?.complete"
                  class="pa-5"
                  flat
                >
                  <p class="ml-0 ml-md-5">
                    <strong>Annual review</strong>
                    has not yet been completed
                  </p>
                </v-card>
              </div>
              <v-container
                v-else
                class="pa-0 pa-md-5"
              >
                <NoPermissionCard />
              </v-container>
            </template>
          </AdsExpansionPanel>
        </Wrapper>
      </div>
      <NoPermissionCard
        v-else
        class="px-0"
      />
    </v-container>
    <!-- PERSONAL NOTES -->
    <PersonalNotesCard
      class="my-10 pa-0 page-break-before"
      :comments="supervisorNotes"
    />
  </tbody>
</template>

<script>
import { AdsExpansionPanel, ADS_Colors } from '@nswdoe/doe-ui-core';
import OrientationBanner from '@/components/OrientationBanner.vue';
import TSUserBannerPrint from '@/components/print/TSUserBannerPrint';
import TSPlanReadOnly from '@/components/TSPlanReadOnly.vue';
import TSPdpGoalsPrint from '@/components/print/TSPdpGoalsPrint.vue';
import TSPdpObservationsPrint from '@/components/print/TSPdpObservationsPrint.vue';
import PrintTabs from '@/components/print/PrintTabs.vue';
import NoPermissionCard from '@/components/print/NoPermissionCard.vue';
import PersonalNotesCard from '@/components/print/PersonalNotesCard';
import Wrapper from '@/components/Wrapper.vue';
import { formatDateTime, formatName } from '@/utils';
import { TAB_TYPES } from '@/data/constants';
import { includes } from 'ramda';
import { mapGetters } from 'vuex';
export default {
  name: 'TSSupervisorPrintContent',
  components: {
    AdsExpansionPanel,
    OrientationBanner,
    TSUserBannerPrint,
    TSPlanReadOnly,
    TSPdpGoalsPrint,
    TSPdpObservationsPrint,
    PrintTabs,
    NoPermissionCard,
    PersonalNotesCard,
    Wrapper,
  },
  props: {
    actionPdp: {
      type: Object,
      default: () => ({}),
    },
    planSection: {
      type: Object,
      default: () => ({}),
    },
    midYearSection: {
      type: Object,
      default: () => ({}),
    },
    finalSection: {
      type: Object,
      default: () => ({}),
    },
    midYearSupervisor: {
      type: Object,
      default: () => ({}),
    },
    finalSupervisor: {
      type: Object,
      default: () => ({}),
    },
    activeSection: {
      type: Object,
      default: () => ({}),
    },
    isCurrentSupervisor: {
      type: Boolean,
      required: true,
    },
    isPlanSupervisor: {
      type: Boolean,
      required: true,
    },
    isMidYearSupervisor: {
      type: Boolean,
      required: true,
    },
    supervisorName: {
      type: String,
      default: '',
    },
    showTabs: {
      validator: (value) => {
        return value === null || typeof value === 'boolean';
      },
      required: true,
    },
    selectedTab: {
      type: String,
      required: true,
    },
    owner: {
      type: Object,
      default: () => {
        return {};
      },
    },
    userIsPrincipal: {
      type: Boolean,
      required: true,
    },
    isSigningPrintBanner: {
      type: Boolean,
      required: true,
    },
    supervisorNotes: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      ADS_Colors,
      TAB_TYPES,
      tabs: [
        TAB_TYPES.plan,
        TAB_TYPES.goalsAndEvidence,
        TAB_TYPES.observations,
        TAB_TYPES.reviews,
      ],
      reviewPanels: [0, 1],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    availableTabs() {
      return [
        ...(this.isCurrentSupervisor ||
        this.isPlanSupervisor ||
        this.userIsPrincipal
          ? [TAB_TYPES.plan]
          : []),
        ...(this.isCurrentSupervisor ||
        this.isMidYearSupervisor ||
        this.userIsPrincipal
          ? [
              TAB_TYPES.goalsAndEvidence,
              TAB_TYPES.observations,
              TAB_TYPES.reviews,
            ]
          : []),
      ];
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    ownerIsPrincipal() {
      return this.actionPdp?.owner?.isPrincipal;
    },
  },
  methods: {
    getTabIndexById(tabId) {
      return this.tabs.findIndex((tab) => tab.id === tabId);
    },
    canViewSection(tab) {
      return includes(tab, this.availableTabs);
    },
    formatName,
    formatDateTime(date) {
      return formatDateTime(date);
    },
  },
  provide: {
    isPrint: true,
  },
};
</script>

<style scoped lang="scss">
.page-break-before {
  break-before: page;
}
.v-tabs {
  pointer-events: none;
}
.ts-user-banner--print {
  &.banner1 {
    color: $ads-grey-01;
    :deep .v-icon {
      color: $ads-grey-01;
    }
  }
}
:deep .v-expansion-panel {
  break-inside: avoid;
}
</style>
