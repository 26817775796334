import { Lookups } from '@/schema';
import { get } from '@/api/http';

const basePath = process.env.VUE_APP_IS_LAMBDA ? '/v2' : '';

const getLookups = async (): Promise<Lookups | undefined> => {
  const url = `${basePath}/lookups/`;
  const response = await get<Lookups>(url);
  if (response.data) {
    return response.data;
  }
  return undefined;
};

export default {
  getLookups,
};
