<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="auto">
        <p
          class="d-flex flex-row ml-sm-1 body-1 ml-1 mr-1 mr-sm-0 align-center"
        >
          Filter by:
          <ChipFilter
            v-if="archived"
            v-model="selectedYear"
            :items="filterYear"
            class="ml-3"
            name="Year"
            type="checkbox"
          />
          <ChipFilter
            v-model="selectedRole"
            :items="filterRoles"
            class="ml-3"
            name="Role"
            type="checkbox"
          />
          <ChipFilter
            v-if="tasks || team || school"
            v-model="selectedPhase"
            :items="filterPhases"
            class="ml-3"
            name="Phase"
            type="checkbox"
          />
          <ChipFilter
            v-if="tasks || team || school"
            v-model="selectedAction"
            :items="filterActions"
            class="ml-3"
            name="Action"
            type="checkbox"
          />
        </p>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        <AdsDataTable
          :footer-props="{
            'items-per-page-options': TABLE_ITEMS_PER_PAGE_OPTIONS,
          }"
          :headers="TABLE_HEADERS"
          search-label="Find by name"
          :options.sync="options"
          :items="pdps"
          class="default-text"
          item-key="id"
        >
          <template #no-data>
            <v-container
              v-if="
                type === TAB_TYPES.tasks.id && pdps?.length === 0 && !isLoading
              "
              class="my-16"
            >
              <FeatureIcon
                class="mx-auto"
                iconSize="80"
                size="80"
                :color="ADS_Colors.Success_Green"
                backgroundColor="transparent"
                icon="mdi-check-circle-outline"
              />
              <div class="ads-grey-01 no-tasks-message mt-5">
                <p class="text-center mb-0">You have no outstanding tasks.</p>
              </div>
            </v-container>
            <v-skeleton-loader
              v-if="isLoading"
              type="table-row-divider@1"
            ></v-skeleton-loader>
          </template>

          <template #[`item.updated_at`]="{ item }">
            <div class="table-item ma-0 py-5 flex-row">
              <p
                class="ma-0"
                v-dompurify-html:plaintext="
                  formatDate(item.updated_at ?? item.created_at)
                "
              />
            </div>
          </template>
          <template #[`item.archived_at`]="{ item }">
            <div class="table-item ma-0 py-5 flex-row">
              <p
                class="ma-0"
                v-dompurify-html:plaintext="formatDate(item.archived_at)"
              />
            </div>
          </template>
          <template #[`item.owner.staffPreferredName`]="{ item }">
            <div class="d-flex align-start">
              <div class="py-5">
                <InitialsIcon
                  class="mr-md-4"
                  :givenName="formatName(item.owner)"
                  size="44px"
                />
              </div>
              <div
                class="d-flex flex-column justify-start table-item ma-0 py-5"
                style="height: auto"
              >
                <p
                  class="ma-0 font-weight-bold"
                  v-dompurify-html:plaintext="formatName(item.owner)"
                />
                <p
                  class="ma-0"
                  v-dompurify-html:plaintext="
                    getPositions(item.owner?.staffEmployment)
                  "
                />
              </div>
            </div>
          </template>
          <template #[`item.role`]="{ item }">
            <div class="table-item ma-0 py-5 flex-row">
              <p
                class="ma-0"
                v-dompurify-html:plaintext="item.role"
              />
            </div>
          </template>
          <template #[`item.phase.stage`]="{ item }">
            <div class="table-item ma-0 py-5 flex-row">
              <p
                class=""
                v-dompurify-html:sanitize="
                  `<strong>
                                ${
                                  item.phase?.status?.length === 0
                                    ? ''
                                    : `${item.phase?.stage}: `
                                }
                              </strong>
                              ${
                                item.phase?.status?.length === 0
                                  ? item.phase?.stage
                                  : item.phase?.status
                              }`
                "
              />
            </div>
          </template>
          <template #[`item.action`]="{ item }">
            <div class="table-item ma-0 py-5 flex-row">
              <AdsButton
                v-if="archived"
                class="body-1 font-weight-bold"
                :button-text="item.action"
                secondary
                @click="handleActionClick(item)"
              />
              <div
                v-else-if="item.action == 'Submission pending'"
                class="body-1 font-weight-bold"
              >
                Submission pending
              </div>
              <Chip
                v-else
                :color="getActionColour(item.action)"
                class="body-1 font-weight-bold"
                @click="handleActionClick(item)"
                data-testid="handleAction"
                :text="item.action"
              />
            </div>
          </template>
        </AdsDataTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  AdsButton,
  AdsDataTable,
  Chip,
  ChipFilter,
  FeatureIcon,
  InitialsIcon,
} from '@nswdoe/doe-ui-core/index';
import { mapGetters } from 'vuex';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { formatDate, formatName, getFilterYear, getPositions } from '@/utils';
import {
  ACTIONS,
  ROLES,
  TAB_TYPES,
  TABLE_ITEMS_PER_PAGE_OPTIONS,
} from '@/data/constants';

export default {
  name: 'SuperviseeTable',
  components: {
    Chip,
    ChipFilter,
    AdsDataTable,
    FeatureIcon,
    InitialsIcon,
    AdsButton,
  },
  data() {
    return {
      TABLE_ITEMS_PER_PAGE_OPTIONS,
      TAB_TYPES,
      ADS_Colors,
      options: {},
      selectedRole: [],
      selectedPhase: [],
      selectedAction: [],
      selectedYear: [],
      tasks: this.type === TAB_TYPES.tasks.id,
      team: this.type === TAB_TYPES.team.id,
      school: this.type === TAB_TYPES.school.id,
      archived: this.type === TAB_TYPES.archived.id,
      filterRoles: [],
      filterPhases: [
        {
          id: 'Plan',
          name: 'Plan',
        },
        {
          id: 'Implement',
          name: 'Implement',
        },
        {
          id: 'Review',
          name: 'Review',
        },
        {
          id: 'Complete',
          name: 'Complete',
        },
      ],
      filterActions: [
        {
          id: 'MEET / SIGN',
          name: 'Meet / Sign',
        },
        {
          id: 'VIEW / SIGN',
          name: 'View / Sign',
        },
        {
          id: 'NONE',
          name: 'None',
        },
        {
          id: 'Submission pending',
          name: 'Submission pending',
        },

        ...(this.school ? [{ id: 'INACTIVE', name: 'Inactive' }] : []),
      ],
      TABLE_HEADERS: [
        {
          text: 'Date',
          value:
            this.type === TAB_TYPES.archived.id ? 'archived_at' : 'updated_at',
          align: 'start',
          width: '15%',
        },
        {
          text: 'Name',
          value: 'owner.staffPreferredName',
          align: 'start',
          width: '25%',
        },
        {
          text: 'Your Role',
          value: 'role',
          align: 'start',
          width: '20%',
          filter: (value) => {
            if (this.selectedRole.length === 0) return true;
            return this.selectedRole.some((element) => {
              return value === element;
            });
          },
        },
        {
          text:
            this.type === TAB_TYPES.archived.id ? 'PDP Status' : 'PDP Phase',
          value: 'phase.stage',
          align: 'start',
          width: '25%',
          filter: (value) => {
            if (this.selectedPhase.length === 0) return true;
            return this.selectedPhase.some((element) => {
              return value === element;
            });
          },
        },
        {
          text: this.type === TAB_TYPES.archived.id ? '' : 'Action',
          value: 'action',
          align: 'center',
          width: '15%',
          filter: (value) => {
            if (this.selectedAction.length === 0) return true;
            return this.selectedAction.some((element) => {
              return value.includes(element);
            });
          },
        },
        {
          text: 'Year',
          value: 'year',
          align: ' d-none',
          filter: (value) => {
            if (this.selectedYear.length === 0) return true;
            return this.selectedYear.some((element) => {
              return value === element;
            });
          },
        },
      ],
      filterYear: [],
    };
  },
  props: {
    type: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters([
      'loggedInUser',
      'taskPdp',
      'superviseePdp',
      'schoolPdp',
      'archiveManagementPdp',
      'taskPdpLength',
      'superviseePdpLength',
      'schoolPdpLength',
      'archiveManagementPdpLength',
    ]),
    pdps() {
      let result = [];
      switch (this.type) {
        case TAB_TYPES.tasks.id:
          result = this.taskPdp;
          break;
        case TAB_TYPES.team.id:
          result = this.superviseePdp;
          break;
        case TAB_TYPES.school.id:
          result = this.schoolPdp ?? [];
          break;
        case TAB_TYPES.archived.id:
          result = this.archiveManagementPdp;
          this.setFilterYear(result);
          break;
        default:
          result = [];
      }
      this.setFilterRoles();
      return result;
    },
  },
  methods: {
    formatDate,
    formatName,
    handleActionClick(pdp) {
      switch (pdp.action) {
        case ACTIONS.meetSign:
        case ACTIONS.viewSign:
        case ACTIONS.view:
          this.$emit('action-click', pdp.id);
          break;
        case ACTIONS.none:
          if (this.school || pdp.onboardingComplete) {
            this.$emit('action-click', pdp.id);
          }
          break;
        case ACTIONS.pending:
          break;
        default:
          break;
      }
    },
    getPositions,
    getActionColour(action) {
      switch (action) {
        case 'NONE':
          return ADS_Colors.GREY_02;
        case 'MEET / SIGN':
          return ADS_Colors.PRIMARY_BLUE;
        case 'VIEW / SIGN':
          return ADS_Colors.PRIMARY_BLUE_DARK;
        case 'INACTIVE':
          return ADS_Colors.Light_40;
        case 'Submission pending':
          return ADS_Colors.GREY_02;
        default:
          return '';
      }
    },
    setFilterYear(pdps) {
      if (pdps) {
        this.filterYear = getFilterYear(pdps);
      }
    },
    setFilterRoles() {
      this.filterRoles = [
        {
          id: ROLES.pdpSupervisor,
          name: ROLES.pdpSupervisor,
        },
        {
          id: ROLES.pastPdpSupervisor,
          name: ROLES.pastPdpSupervisor,
        },
        {
          id: ROLES.pdpAdditionalSupervisor,
          name: ROLES.pdpAdditionalSupervisor,
        },
        {
          id: ROLES.pdpPrincipal,
          name: ROLES.pdpPrincipal,
        },
        {
          id: ROLES.pastPdpPrincipal,
          name: ROLES.pastPdpPrincipal,
        },
        ...((this.type === TAB_TYPES.school.id ||
          this.type === TAB_TYPES.archived.id) &&
        this.loggedInUser?.isPrincipal
          ? [
              {
                id: ROLES.principal,
                name: ROLES.principal,
              },
            ]
          : []),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.default-text {
  font-size: 1rem;
}

.v-data-table.row-height-50 td {
  height: 18px !important;
}

.table-item {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  height: 98px;
}

.no-tasks-message {
  font-family: $body-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}
</style>
