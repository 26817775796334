<template>
  <v-card class="no-break-inside">
    <v-row
      class="px-5 px-md-0"
      no-gutters
    >
      <v-col>
        <p>
          <b class="title">
            <v-icon
              color="success"
              class="mr-2"
            >
              mdi-message-reply-text-outline
            </v-icon>
            {{ label }}
          </b>
        </p>
      </v-col>
    </v-row>
    <v-card
      v-for="(comment, index) in comments"
      :key="`comment-${index}-${comment?.time}`"
      class="mx-0 mx-md-0 pa-5 mb-5"
    >
      <p
        class="pa-0 ma-0"
        v-dompurify-html:sanitize="
          `<strong>${comment?.role} ${comment?.name} </strong>${
            comment?.action
          } this ${
            comment?.action === 'archived' ? 'PDP' : 'section'
          } on <strong>${comment?.time}</strong>`
        "
      />
    </v-card>
  </v-card>
</template>

<script>
import { getComments } from '@/components/EventLog.lib';

export default {
  name: 'EventLog',
  props: {
    owner: {
      type: Object,
      default: () => null,
    },
    section: {
      type: Object,
      default: () => null,
    },
    pdp: {
      type: Object,
      default: () => null,
    },
    supervisor: {
      type: Object,
      default: () => null,
    },
    manager: {
      type: Object,
      default: () => null,
    },
    principal: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    label() {
      if (this.owner.isTeachingStaff) {
        return 'Comments';
      } else return 'Timeline';
    },
    comments() {
      return getComments(
        this.section,
        this.owner,
        this.supervisor,
        this.principal,
        this.manager,
        this.pdp
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
