<template>
  <v-container class="pa-0 px-md-10 my-10">
    <SignaturePanel
      v-if="
        hasMidyearReviewBeenUpdated &&
        !showNotifySupervisor &&
        !midYearSection.complete &&
        !isPdpArchived
      "
      :owner="formPdp?.owner"
      :users="formPdp?.pdp_user_associations"
      :section="midYearSection"
      @signed="handleSignedForm"
      class="mb-10"
      :disableSign="!isSectionValid"
    />
    <v-card
      v-if="!midYearSection.complete && !isPdpArchived"
      class="py-10 px-0"
    >
      <div class="py-4 px-md-10 display-1 ads-grey-01">
        Mid-year self-assessment
      </div>
      <v-row class="px-md-10">
        <v-col class="ads-grey-01">
          <p>
            Reflect on your progress against your goals (PLAN Parts A and B).
          </p>
          <p>
            Work with your PDP Supervisor to ensure you are on track with your
            plan and/or to identify any barriers, challenges or successes.
          </p>
        </v-col>
        <v-col>
          <v-card
            class="px-6 pt-5 pb-md-0 rounded-12"
            color="primary-teal"
            elevation="0"
          >
            <v-row
              class="d-flex"
              no-gutters
            >
              <v-col
                class="flex-grow-1 pb-7 pb-md-8 pr-2"
                cols="9"
              >
                <v-card-title
                  class="pa-0 pb-3 font-weight-bold primary-blue--text"
                >
                  Useful links
                </v-card-title>
                <v-card-text class="d-flex px-0 grey-01--text font-weight-bold">
                  <a
                    class="subtitle-1"
                    href="https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/meaningful-conversations"
                    target="_blank"
                  >
                    Meaningful conversations to help us be at our best
                    <v-icon class="ml-2 ads-primary-blue">
                      mdi-exit-to-app
                    </v-icon>
                  </a>
                </v-card-text>
              </v-col>
              <v-col
                v-if="!isMobile"
                class="d-flex justify-end align-end image-col"
              >
                <v-img
                  class="fill-height image-content"
                  src="../../public/gender-neutral-clipboard.svg"
                  max-width="116px"
                  max-height="115px"
                ></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <div class="mt-6 px-md-10">
        <div>
          <p class="mysa-section-title">
            <v-icon
              color="success"
              class="mr-2"
            >
              mdi-content-paste
            </v-icon>
            Progress Report
          </p>
        </div>
        <p class="body-1">
          What has been achieved? What is still to be completed for each
          identified goal? Think about your development activities, have you
          addressed the capabilities identified?
        </p>
        <RichTextField
          v-model.trim="formPdp.midYearText"
          label="Add details"
          :rules="[
            rules.stringLengthRequired(NTS_PROGRESS_REPORT_MIN_LENGTH),
            rules.maxStringLengthRule(NTS_PROGRESS_REPORT_MAX_LENGTH),
          ]"
          placeholder="Please enter between 50-5000 characters, approx. 10-500 words."
          lazy
          :disabled="readOnly"
          @validated="handleMidYearTextValidated"
        />
      </div>
      <v-divider />
      <div class="mt-6 px-md-10">
        <div>
          <p class="mysa-section-title">
            <v-icon
              color="success"
              class="mr-2"
            >
              mdi-message-reply-text-outline
            </v-icon>
            Comments
          </p>
        </div>
        <p class="body-1">
          Briefly note if there are any changes to goals and associated
          activities, goals that are no longer required and any new goals, and
          reasons for the changes. Identify any factors impacting achievement of
          goals and options for addressing these.
        </p>
        <RichTextField
          v-model.trim="reviewComment"
          label="Add details"
          :disabled="readOnly"
        />
      </div>
      <div class="px-md-10">
        <AdsButton
          buttonText="Update"
          icon="mdi-arrow-right"
          :disabled="hasMidyearReviewBeenUpdated"
          @click="handleUpdateMidYearReviewDetails"
        ></AdsButton>
      </div>
    </v-card>
    <v-card
      class="mt-10 pa-10"
      v-if="showNotifySupervisor && !isPdpArchived"
    >
      <p class="body-1 ads-grey-01">
        Confirm you are ready to make your mid-year self-assessment available
        to:
      </p>
      <div
        v-for="pdpUser in pdpUsers"
        :key="pdpUser.id"
        class="d-flex mb-7"
      >
        <InitialsIcon
          class="mr-md-4"
          :givenName="pdpUser.displayedName"
          size="42px"
        ></InitialsIcon>
        <div class="body-1 ads-grey-01">
          <div>
            <strong>{{ pdpUser.displayedName }}</strong>
          </div>
          <div>{{ pdpUser.pdpRole }}</div>
        </div>
      </div>
      <p class="mb-8 body-1 ads-grey-01">
        You will still be able to make changes to your mid-year self-assessment
        until it is signed.
      </p>
      <AdsButton
        buttonText="Notify supervisor"
        icon="mdi-arrow-right"
        type="submit"
        @click.prevent="handleNotifySupervisor"
      />
    </v-card>
    <v-row
      v-if="!finalSection?.complete && !isPdpArchived"
      class="flex-column"
      no-gutters
    >
      <v-col v-if="midYearSection?.complete">
        <NTSReviewReadOnly
          :pdp="formPdp"
          :section="midYearSection"
        />
      </v-col>
      <v-col>
        <v-card class="pa-10 my-10">
          <NTSGoals
            :section="midYearSection"
            :type="GOAL_TYPE.work"
            :readOnly="readOnly"
            validation
            @goalsAltered="handleUpdateGoals"
          />
          <h4 class="display-1 mt-10 mb-5 mx-md-0">Career goals</h4>
          <NTSGoals
            :section="midYearSection"
            :type="GOAL_TYPE.career"
            :readOnly="readOnly"
            validation
            @goalsAltered="handleUpdateGoals"
          />
        </v-card>
      </v-col>
      <v-col>
        <EventLog
          v-if="midYearSection?.notifySupervisor || isPdpArchived"
          :section="midYearSection"
          :owner="pdp?.owner"
          :supervisor="supervisor"
          :manager="manager"
          :principal="principal"
          :pdp="pdp"
          class="px-0 px-md-10 py-8"
        />
      </v-col>
    </v-row>
    <v-row
      v-else
      class="my-10 mx-n10"
      :class="{ 'mx-n10': !readOnly }"
      no-gutters
    >
      <v-col>
        <NTSSectionReadOnly
          v-if="!readOnly"
          :pdp="formPdp"
          :section="midYearSection"
        />
        <NTSSectionReadOnlyPrint
          v-else
          :pdp="formPdp"
          :section="midYearSection"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AdsButton, InitialsIcon } from '@nswdoe/doe-ui-core';
import { mapGetters, mapActions } from 'vuex';
import { equals } from 'ramda';
import SignaturePanel from '@/components/SignaturePanel.vue';
import NTSGoals from '@/components/NTSGoals.vue';
import { getDisplayUsers } from '@/utils';
import NTSReviewReadOnly from '@/components/NTSReviewReadOnly.vue';
import NTSSectionReadOnly from '@/components/NTSSectionReadOnly.vue';
import NTSSectionReadOnlyPrint from '@/components/print/NTSSectionReadOnlyPrint.vue';
import EventLog from '@/components/EventLog.vue';
import validators from '@/lib/validators';
import {
  COMMENTS_MAX_LENGTH,
  COMMENTS_MIN_LENGTH,
  GOAL_TYPE,
  NTS_PROGRESS_REPORT_MAX_LENGTH,
  NTS_PROGRESS_REPORT_MIN_LENGTH,
  SECTION_TYPE,
  USER_TYPE,
} from '@/data/constants';
import {
  isNtsGoalsValid,
  isLearningThemeAssociationsValid,
} from '@/lib/dataValidation';
import { setNTSNotifySupervisor } from '@/lib/pdp';
import RichTextField from '@/components/RichTextField.vue';

export default {
  name: 'NTSMidYearReview',
  components: {
    RichTextField,
    NTSReviewReadOnly,
    AdsButton,
    InitialsIcon,
    SignaturePanel,
    EventLog,
    NTSGoals,
    NTSSectionReadOnly,
    NTSSectionReadOnlyPrint,
  },
  props: {
    valid: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMidYearTextValid: false,
      GOAL_TYPE,
      rules: {
        maxStringLength: validators.maxStringLength,
        stringLengthRequired: validators.stringLengthRequired,
        maxStringLengthRule: validators.maxStringLengthRule,
      },
      NTS_PROGRESS_REPORT_MIN_LENGTH,
      NTS_PROGRESS_REPORT_MAX_LENGTH,
      COMMENTS_MIN_LENGTH,
      COMMENTS_MAX_LENGTH,
    };
  },
  computed: {
    ...mapGetters([
      'formPdp',
      'pdp',
      'isPdpArchived',
      'isPdpIncompleteArchived',
    ]),
    ...mapGetters('pdp', {
      originalMidYearSection: 'pdpMidYearSection',
      midYearSection: 'formPdpMidYearSection',
      finalSection: 'formPdpFinalSection',
    }),
    pdpUsers() {
      return getDisplayUsers(this.pdp.pdp_user_associations);
    },
    reviewComment: {
      get() {
        return this.midYearSection?.userComment || '';
      },
      set(value) {
        if (this.formPdp) {
          this.formPdp.sections.map((section) => {
            if (section.sectionType.toUpperCase() === SECTION_TYPE.midYear) {
              section.userComment = value;
              return section;
            }
          });
        }
      },
    },
    showNotifySupervisor() {
      return !this.midYearSection?.notifySupervisor;
    },
    originalComment() {
      return this.originalMidYearSection?.userComment || '';
    },
    hasMidyearReviewBeenUpdated() {
      return (
        equals(this.originalComment, this.reviewComment) &&
        equals(this.pdp?.midYearText, this.formPdp?.midYearText)
      );
    },
    isSectionValid() {
      return (
        this.isMidYearTextValid &&
        isNtsGoalsValid(this.midYearSection) &&
        isLearningThemeAssociationsValid(
          this.midYearSection?.section_learning_theme_associations
        )
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    supervisor() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.supervisor &&
          user.user_id === this.midYearSection?.supervisor_id
      )?.user;
    },
    manager() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.additionalSupervisor &&
          user.user_id === this.midYearSection?.manager_id
      )?.user;
    },
    principal() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.pdpPrincipal &&
          user.user_id === this.midYearSection?.principal_id
      )?.user;
    },
  },
  methods: {
    ...mapActions('pdp', ['savePdp']),
    async handleUpdateMidYearReviewDetails() {
      this.formPdp.sections = setNTSNotifySupervisor(
        SECTION_TYPE.midYear,
        this.formPdp,
        false
      );
      await this.savePdp(this.formPdp);
    },
    async handleNotifySupervisor() {
      if (this.midYearSection) {
        this.formPdp.sections = setNTSNotifySupervisor(
          SECTION_TYPE.midYear,
          this.formPdp
        );
        await this.savePdp(this.formPdp);
        this.$emit('validate');
      }
      this.$vuetify.goTo(0);
    },
    async handleUpdateGoals() {
      await this.savePdp(this.formPdp);
      this.$emit('validate');
    },
    async handleSignedForm(sectionToBeUpdated, userType) {
      if (sectionToBeUpdated && userType === USER_TYPE.owner) {
        this.$emit('signed', sectionToBeUpdated);
      }
    },
    handleMidYearTextValidated(value) {
      this.isMidYearTextValid = value;
    },
  },
  emits: ['signed', 'validate'],
};
</script>

<style lang="scss" scoped>
.v-divider {
  min-width: 100%;
}
.goal-heading {
  background-color: rgba(108, 172, 228, 0.2);
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.goal-text {
  color: $ads-dark;
  white-space: pre-line;
  font-size: 1rem;
}

:deep .v-messages:not(.error--text) {
  color: $ads-navy;
  font-size: 16px;
}

.rounded-12 {
  border-radius: 12px;
}

.fill-height {
  height: 100%;
}
.image-col {
  position: relative; /* Enable positioning of absolutely positioned image */
}
.image-content {
  position: absolute;
  width: auto; /* Adjust width as necessary */
}
:deep .v-card {
  &__title {
    font-size: 24px;
    line-height: 30px;
    word-break: break-word;
  }
  &__text {
    font-size: 16px;
    line-height: 24px;
  }
  .v-image {
    bottom: -14px;
  }
}

.mysa-section-title {
  color: #000;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
</style>
