import { Goal, Pdp, Section } from '@/schema';
import {
  GOAL_TYPE,
  MAX_TS_GOALS,
  MAX_WORK_GOALS,
  MIN_TS_GOALS,
  MIN_WORK_GOALS,
  SECTION_TYPE,
  TS_GOAL_DESCRIPTION_MIN_LENGTH,
  WORK_GOAL_DESCRIPTION_MAX_LENGTH,
  WORK_GOAL_DESCRIPTION_MIN_LENGTH,
} from '@/data/constants';
import rules from './validators';
import { isBoolInSet } from '@/utils';

export const isNtsGoalsValid = (section: Section) => {
  const workGoals = section.goals?.filter(
    (item) => item.type === GOAL_TYPE.work
  );
  const correctNumber = workGoals
    ? workGoals?.length >= MIN_WORK_GOALS && workGoals?.length <= MAX_WORK_GOALS
    : false;
  const descriptionInValid = workGoals?.some((goal: Goal) => {
    return !isNtsWorkGoalValid(goal);
  });
  const errors = [!correctNumber, !!descriptionInValid];
  return !isBoolInSet(errors, true);
};

export const isNtsWorkGoalValid = (goal: Goal) => {
  return (
    rules.stringLengthRequired(WORK_GOAL_DESCRIPTION_MIN_LENGTH)(
      goal.description
    ) === true &&
    rules.maxStringLengthRule(WORK_GOAL_DESCRIPTION_MAX_LENGTH)(
      goal.description
    ) === true
  );
};

export const isLearningThemeAssociationsValid = (
  sectionLearningThemeAssociations:
    | Section['section_learning_theme_associations']
    | []
) => {
  return sectionLearningThemeAssociations?.some(
    (learningTheme) => !learningTheme?.markForDelete
  );
};

export const isTsGoalsValid = (section: Section) => {
  const correctNumber = section.goals
    ? section.goals?.length >= MIN_TS_GOALS &&
      section.goals?.length <= MAX_TS_GOALS
    : false;
  const descriptionInValid = section.goals?.some((goal: Goal) => {
    return !isTsGoalValid(goal);
  });
  const errors = [!correctNumber, !!descriptionInValid];
  return !isBoolInSet(errors, true);
};

export const isTsGoalValid = (goal: Goal) => {
  return (
    rules.stringFieldRequired(goal.description) === true &&
    rules.stringLengthRequired(TS_GOAL_DESCRIPTION_MIN_LENGTH)(
      goal.description
    ) === true
  );
};
export const isMidYearValid = (pdp: Pdp) => {
  const midYearTextInvalid =
    pdp.midYearText === null ||
    pdp.midYearText === undefined ||
    pdp.midYearText.length < 50 ||
    pdp.midYearText.length > 5000;
  return !midYearTextInvalid;
};

export const isAnnualTextValid = (pdp: Pdp) => {
  return !(
    pdp?.annualText === null ||
    pdp?.annualText === undefined ||
    pdp?.annualText?.length < 50 ||
    pdp?.annualText?.length > 5000
  );
};

export const isSupervisorCommentValid = (pdp: Pdp) => {
  const finalSection = pdp.sections?.find(
    (section) => section.sectionType === SECTION_TYPE.final
  );
  return !(
    finalSection?.supervisorComment === null ||
    finalSection?.supervisorComment === undefined ||
    finalSection?.supervisorComment?.length < 1 ||
    finalSection?.supervisorComment?.length > 5000
  );
};

export const isFinalValid = (pdp: Pdp) => {
  const annualTextInvalid =
    pdp?.annualText === null ||
    pdp?.annualText === undefined ||
    pdp?.annualText?.length < 50 ||
    pdp?.annualText?.length > 5000;

  const finalSection = pdp.sections?.find(
    (section) => section.sectionType === SECTION_TYPE.final
  );

  const supervisorCommentInvalid =
    finalSection?.supervisorComment === null ||
    finalSection?.supervisorComment === undefined ||
    finalSection?.supervisorComment?.length < 1 ||
    finalSection?.supervisorComment?.length > 5000;
  return !annualTextInvalid && !supervisorCommentInvalid;
};
