import { render, staticRenderFns } from "./TSPdpObservationsPrint.vue?vue&type=template&id=1bb6b115&scoped=true"
import script from "./TSPdpObservationsPrint.vue?vue&type=script&lang=js"
export * from "./TSPdpObservationsPrint.vue?vue&type=script&lang=js"
import style0 from "./TSPdpObservationsPrint.vue?vue&type=style&index=0&id=1bb6b115&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bb6b115",
  null
  
)

export default component.exports