<template>
  <div>
    <!-- Expired PDP Dialog -->
    <Dialog
      :displayCloseBtn="false"
      :openDialog="showExpiredPdpDialog"
      maxWidth="60%"
    >
      <template #text>
        <PdpUpdateDialog
          class="ma-0 px-7"
          :openDialog="showExpiredPdpDialog"
          @closeExpiredPdpDialog="closeExpiredPdpDialog"
        />
      </template>
    </Dialog>
    <!-- ... Expired PDP Dialog -->
    <v-app-bar
      color="primary-blue"
      elevation="0"
    >
      <AdsButton
        :buttonText="`${formatName(actionPdp?.owner)} PDP`"
        :ripple="false"
        class="ma-0 pa-2"
        icon="mdi-close"
        left
        style="
          border-style: none !important;
          background-color: rgba(0, 0, 0, 0) !important;
          color: white !important;
        "
        tertiary
        @click.stop="handleBackButton"
      />
    </v-app-bar>
    <UserBanner
      :user="actionPdp?.owner"
      :pdp="actionPdp"
    />
    <Ribbon
      :pdp="actionPdp"
      :planSection="planSection"
      @selected="pdpSelected"
      @print="getNotes"
    >
      <template #printContent>
        <NTSSupervisorPrintContent
          v-if="actionPdp.active"
          :actionPdp="actionPdp"
          :planSection="planSection"
          :isFormValid="isFormValid"
          :isCurrentPdpPrincipal="isCurrentPdpPrincipal"
          :isCurrentSupervisor="isCurrentSupervisor"
          :isCurrentRole="isCurrentRole"
          :isPlanRole="isPlanRole"
          :isMidYearRole="isMidYearRole"
          :userIsSchoolPrincipal="userIsSchoolPrincipal"
          :showAnnualReviewSupervisorSignaturePanel="
            !!showAnnualReviewSupervisorSignaturePanel
          "
          :principal="principal"
          :supervisor="supervisor"
          :isSectionValid="isSectionValid"
          :readyToSignAnnualReview="readyToSignAnnualReview"
          :hasPdpPrincipalSignedPlan="hasPdpPrincipalSignedPlan"
          :hasPdpPrincipalSignedMidYear="hasPdpPrincipalSignedMidYear"
          :pdpUserNotes="notes"
        />
        <NTSSupervisorArchivedPrintContent
          v-else
          :actionPdp="actionPdp"
          :planSection="planSection"
          :isCurrentPdpPrincipal="isCurrentPdpPrincipal"
          :isCurrentSupervisor="isCurrentSupervisor"
          :isCurrentRole="isCurrentRole"
          :isPlanRole="isPlanRole"
          :isMidYearRole="isMidYearRole"
          :userIsSchoolPrincipal="userIsSchoolPrincipal"
          :principal="principal"
          :supervisor="supervisor"
          :readyToSignAnnualReview="readyToSignAnnualReview"
          :pdpUserNotes="notes"
        />
      </template>
    </Ribbon>
    <v-container
      v-if="!showTabs"
      class="my-10 pa-0"
    >
      <NoPermissionCard
        v-if="!isCurrentRole && !isPlanRole && !userIsSchoolPrincipal"
      />
      <div v-else>
        <SignaturePanel
          class="mt-10 mx-md-10 mb-7"
          :owner="actionPdp?.owner"
          :users="actionPdp?.pdp_user_associations"
          :section="planSection"
          :disableSign="!isSectionValid"
          @signed="handleSignedForm"
          v-if="
            planSection?.notifySupervisor &&
            !planSection?.complete &&
            (isCurrentSupervisor || isCurrentPdpPrincipal) &&
            !isActionPdpArchived
          "
        />
        <NTSSectionReadOnly
          :pdp="actionPdp"
          :section="planSection"
        />
      </div>
    </v-container>
    <div v-if="showTabs">
      <v-tabs
        v-model="tab"
        class="ma-0 pa-0"
        slider-color="red"
      >
        <v-tab
          v-for="(item, index) in availableTabs"
          :key="`managementtab${index + 1}`"
        >
          {{ item.tab }}
        </v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item key="tabItem1">
            <v-container
              v-if="!isCurrentRole && !isPlanRole && !userIsSchoolPrincipal"
            >
              <NoPermissionCard />
            </v-container>
            <div v-else>
              <SignaturePanel
                class="mt-10 mx-md-10 mb-7"
                :owner="actionPdp?.owner"
                :users="actionPdp?.pdp_user_associations"
                :section="planSection"
                @signed="handleSignedForm"
                v-if="
                  planSection?.notifySupervisor &&
                  isCurrentPdpPrincipal &&
                  !hasPdpPrincipalSignedPlan &&
                  !actionPdp?.finalCommentLocked &&
                  !isActionPdpArchived
                "
              />
              <NTSSectionReadOnly
                :pdp="actionPdp"
                :section="planSection"
                class="mt-12"
              />
            </div>
          </v-tab-item>
          <v-tab-item key="tabItem2">
            <v-container
              v-if="!isCurrentRole && !isMidYearRole && !userIsSchoolPrincipal"
            >
              <NoPermissionCard />
            </v-container>
            <div v-else>
              <div class="px-md-10">
                <SignaturePanel
                  v-if="
                    midYearSection?.notifySupervisor &&
                    ((!midYearSection?.complete && isCurrentSupervisor) ||
                      (isCurrentPdpPrincipal &&
                        !hasPdpPrincipalSignedMidYear &&
                        !actionPdp?.finalCommentLocked)) &&
                    !isActionPdpArchived
                  "
                  :owner="actionPdp?.owner"
                  :users="actionPdp?.pdp_user_associations"
                  :section="midYearSection"
                  :disableSign="!isSectionValid && !midYearSection?.complete"
                  class="pa-0 mt-10"
                  @signed="handleSignedForm"
                />
              </div>
              <NTSSectionReadOnly
                :pdp="actionPdp"
                :section="midYearSection"
                class="my-5"
              />
            </div>
          </v-tab-item>

          <!-- ANNUAL REVIEW TAB -->
          <v-tab-item key="tabItem3">
            <v-container v-if="!isCurrentRole && !userIsSchoolPrincipal">
              <NoPermissionCard />
            </v-container>
            <div
              v-else
              class="px-md-10"
            >
              <v-form
                ref="tabForm"
                v-model="isFormValid"
                lazy-validation
              >
                <SignaturePanel
                  v-if="
                    showAnnualReviewSupervisorSignaturePanel &&
                    !isActionPdpArchived
                  "
                  :owner="actionPdp?.owner"
                  :users="actionPdp?.pdp_user_associations"
                  :section="finalSection"
                  class="pa-0 mt-10"
                  :disableSign="!isSectionValid || !isFormValid"
                  @signed="handleSignedForm"
                />
                <NTSReviewReadOnly
                  :pdp="actionPdp"
                  :section="finalSection"
                  class="my-10"
                />
                <NTSSupervisorAnnualReviewComment
                  v-if="
                    midYearSection?.complete &&
                    !finalSection?.complete &&
                    isCurrentSupervisor &&
                    finalSection.notifySupervisor
                  "
                  class="my-10"
                  :isPanelOn="readyToSignAnnualReview"
                  @proceed="handleProceedToSignAnnualReview"
                />
              </v-form>
              <EventLog
                :pdp="actionPdp"
                :section="finalSection"
                :owner="actionPdp?.owner"
                :supervisor="supervisor"
                :principal="principal"
                class="my-10 px-0 px-md-10 py-8"
                v-if="finalSection?.notifySupervisor || isActionPdpArchived"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
    <portal
      v-if="
        !isCurrentAdditionalSupervisor &&
        (isCurrentRole || isPlanRole || isMidYearRole)
      "
      selector=".v-application--wrap"
    >
      <div class="notes-wrapper">
        <NotesBar
          v-model="notes"
          :open="notesOpen"
          :edit="actionPdp?.active && pdpUser?.active"
          :archived="!actionPdp.active"
          @openNotes="openNotes"
          @closeNotes="closeNotes"
          @updateNotes="updateNotes"
        />
      </div>
    </portal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AdsButton, Dialog } from '@nswdoe/doe-ui-core/index';
import { formatName } from '@/utils';
import NTSSectionReadOnly from '@/components/NTSSectionReadOnly.vue';
import SignaturePanel from '@/components/SignaturePanel.vue';
import NTSReviewReadOnly from '@/components/NTSReviewReadOnly.vue';
import UserBanner from '@/components/UserBanner.vue';
import NTSSupervisorAnnualReviewComment from '@/components/NTSSupervisorAnnualReviewComment.vue';
import NTSSupervisorPrintContent from '@/components/print/NTSSupervisorPrintContent.vue';
import EventLog from '@/components/EventLog.vue';
import {
  isAnnualTextValid,
  isMidYearValid,
  isSupervisorCommentValid,
  isNtsGoalsValid,
  isLearningThemeAssociationsValid,
} from '@/lib/dataValidation';
import NTSSupervisorArchivedPrintContent from '@/components/print/NTSSupervisorArchivedPrintContent';
import NotesBar from '@/components/NotesBar.vue';
import Ribbon from '@/components/Ribbon.vue';
import PdpUpdateDialog from '@/components/PdpUpdateDialog';
import { TAB_TYPES, USER_TYPE } from '@/data/constants';
import {
  hasUserTypeSigned,
  signNTSSectionAsUserType,
  findPdpUserByType,
  findPdpUserByUserId,
} from '@/lib/pdp';
import NoPermissionCard from '@/components/print/NoPermissionCard.vue';
import { Portal } from '@linusborg/vue-simple-portal';

export default {
  name: 'NTSActionPdp',
  props: {
    selectedTab: {
      type: String,
      default: '',
    },
    actionTab: {
      type: String,
      default: '',
    },
    showExpiredPdpDialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NoPermissionCard,
    NTSReviewReadOnly,
    SignaturePanel,
    NTSSectionReadOnly,
    AdsButton,
    UserBanner,
    NTSSupervisorPrintContent,
    NTSSupervisorAnnualReviewComment,
    NTSSupervisorArchivedPrintContent,
    EventLog,
    NotesBar,
    Ribbon,
    PdpUpdateDialog,
    Dialog,
    Portal,
  },
  data() {
    return {
      tab: 0,
      availableTabs: [TAB_TYPES.plan, TAB_TYPES.implement, TAB_TYPES.review],
      readyToSignAnnualReview: false,
      isFormValid: false,
      isFinalValidatedOnStartup: false,
      notesOpen: false,
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'actionPdp', 'isActionPdpArchived']),
    ...mapGetters('pdp', {
      planSection: 'actionPdpPlanSection',
      midYearSection: 'actionPdpMidYearSection',
      finalSection: 'actionPdpFinalSection',
      personalNotes: 'personalNotes',
    }),
    showAnnualReviewSupervisorSignaturePanel() {
      return (
        !this.isCurrentAdditionalSupervisor &&
        this.finalSection?.notifySupervisor &&
        ((this.readyToSignAnnualReview &&
          this.isCurrentSupervisor &&
          !this.finalSection?.complete) ||
          (this.isCurrentPdpPrincipal &&
            !this.actionPdp?.finalCommentLocked &&
            !this.finalSection.principalSignature))
      );
    },
    showTabs() {
      return (
        this.planSection?.complete ||
        this.isActionPdpArchived ||
        ((this.isPlanRole || this.isMidYearRole) && !this.isCurrentRole)
      );
    },
    pdpUser() {
      let userType;
      switch (true) {
        case this.isCurrentSupervisor:
          userType = USER_TYPE.supervisor;
          break;
        case this.isCurrentPdpPrincipal:
          userType = USER_TYPE.pdpPrincipal;
          break;
        case this.isPlanSupervisor || this.isMidYearSupervisor:
          userType = USER_TYPE.supervisor;
          break;
        case this.isPlanPdpPrincipal || this.isMidYearPdpPrincipal:
          userType = USER_TYPE.pdpPrincipal;
          break;
        default:
          break;
      }
      return userType
        ? findPdpUserByUserId(
            this.actionPdp?.pdp_user_associations,
            this.loggedInUser.id,
            userType
          )
        : null;
    },
    notes: {
      get() {
        return this.personalNotes?.text;
      },
      set(value) {
        this.personalNotes.text = value;
      },
    },
    isCurrentSupervisor() {
      return this.isLoggedInUserOfType(USER_TYPE.supervisor);
    },
    isCurrentPdpPrincipal() {
      return this.isLoggedInUserOfType(USER_TYPE.pdpPrincipal);
    },
    isCurrentAdditionalSupervisor() {
      return this.isLoggedInUserOfType(USER_TYPE.additionalSupervisor);
    },
    isPlanSupervisor() {
      return this.planSection?.supervisor_id === this.loggedInUser?.id;
    },
    isPlanPdpPrincipal() {
      return this.planSection?.principal_id === this.loggedInUser?.id;
    },
    isPlanAdditionalSupervisor() {
      return this.planSection?.manager_id === this.loggedInUser?.id;
    },
    isMidYearSupervisor() {
      return this.midYearSection?.supervisor_id === this.loggedInUser?.id;
    },
    isMidYearPdpPrincipal() {
      return this.midYearSection?.principal_id === this.loggedInUser?.id;
    },
    isMidYearAdditionalSupervisor() {
      return this.midYearSection?.manager_id === this.loggedInUser?.id;
    },
    isCurrentRole() {
      return (
        this.isCurrentSupervisor ||
        this.isCurrentPdpPrincipal ||
        this.isCurrentAdditionalSupervisor
      );
    },
    isPlanRole() {
      return (
        this.isPlanSupervisor ||
        this.isPlanPdpPrincipal ||
        this.isPlanAdditionalSupervisor
      );
    },
    isMidYearRole() {
      return (
        this.isMidYearSupervisor ||
        this.isMidYearPdpPrincipal ||
        this.isMidYearAdditionalSupervisor
      );
    },
    userIsSchoolPrincipal() {
      return (
        (this.selectedTab === TAB_TYPES.school.id ||
          this.selectedTab === TAB_TYPES.archived.id) &&
        this.loggedInUser?.isPrincipal &&
        this.loggedInUser?.schoolCode ===
          this.actionPdp?.owner_school?.schoolCode
      );
    },
    supervisor() {
      return this.actionPdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.supervisor &&
          user.user_id === this.finalSection?.supervisor_id
      )?.user;
    },
    principal() {
      return this.actionPdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.pdpPrincipal &&
          user.user_id === this.finalSection?.principal_id
      )?.user;
    },
    userType() {
      if (this.isCurrentSupervisor) {
        return USER_TYPE.supervisor;
      } else if (this.isCurrentPdpPrincipal) {
        return USER_TYPE.pdpPrincipal;
      } else return undefined;
    },
    isSectionValid() {
      switch (true) {
        case !this.planSection.complete:
          return (
            isNtsGoalsValid(this.planSection) &&
            isLearningThemeAssociationsValid(
              this.planSection?.section_learning_theme_associations
            )
          );
        case !this.midYearSection.complete:
          return (
            isNtsGoalsValid(this.midYearSection) &&
            isLearningThemeAssociationsValid(
              this.midYearSection?.section_learning_theme_associations
            ) &&
            isMidYearValid(this.actionPdp)
          );
        case !this.finalSection.complete:
          return (
            isNtsGoalsValid(this.midYearSection) &&
            isLearningThemeAssociationsValid(
              this.midYearSection?.section_learning_theme_associations
            ) &&
            isAnnualTextValid(this.actionPdp) &&
            (this.isCurrentSupervisor
              ? isSupervisorCommentValid(this.actionPdp)
              : true)
          );
        case this.finalSection.complete:
          return (
            isNtsGoalsValid(this.midYearSection) &&
            isLearningThemeAssociationsValid(
              this.midYearSection?.section_learning_theme_associations
            ) &&
            isAnnualTextValid(this.actionPdp)
          );
        default:
          return false;
      }
    },
    hasPdpPrincipalSignedPlan() {
      return hasUserTypeSigned(this.planSection, USER_TYPE.pdpPrincipal);
    },
    hasPdpPrincipalSignedMidYear() {
      return hasUserTypeSigned(this.midYearSection, USER_TYPE.pdpPrincipal);
    },
  },
  watch: {
    actionTab: {
      async handler() {
        if (this.availableTabs && this.actionTab) {
          await this.pushToCorrectRoute();
          if (this.$refs.tabForm) {
            this.validateTabForm();
          }
        }
      },
      immediate: true,
    },
    tab: {
      async handler() {
        if (this.availableTabs && !this.actionTab && this.showTabs) {
          await this.syncRouteToTab();
          if (this.$refs.tabForm) {
            this.validateTabForm();
          }
        }
      },
      immediate: true,
    },
    isFormValid: {
      handler() {
        if (this.$refs.tabForm && !this.isFinalValidatedOnStartup) {
          this.validateTabForm();
          this.isFinalValidatedOnStartup = true;
        }
      },
    },
  },
  methods: {
    formatName,
    ...mapActions('pdp', [
      'saveActionPdp',
      'getPersonalNotes',
      'updatePersonalNotes',
    ]),
    pdpSelected(id) {
      this.$emit('selected', id);
    },
    handleProceedToSignAnnualReview(value) {
      this.readyToSignAnnualReview = value;
      if (value) this.$vuetify.goTo(0);
    },
    async syncTabToRoute() {
      this.tab = this.availableTabs.findIndex(
        (item) => item.id === this.actionTab
      );
    },
    async syncRouteToTab() {
      const tab = this.availableTabs.findIndex(
        (item) => item.id === this.actionTab
      );
      if (this.tab !== tab) {
        await this.$router.push(
          `/management/${this.selectedTab}/${this.actionPdp?.id}/${
            this.availableTabs[this.tab].id
          }`
        );
      }
    },
    async pushToCorrectRoute() {
      if (this.showTabs && !this.actionTab && this.availableTabs) {
        const firstRoute = `/management/${this.selectedTab}/${this.actionPdp?.id}/${this.availableTabs[0]?.id}`;
        const implementRoute = `/management/${this.selectedTab}/${
          this.actionPdp?.id
        }/${
          this.availableTabs[
            this.availableTabs?.findIndex(
              (item) => item.id === TAB_TYPES.implement.id
            )
          ]?.id
        }`;
        const reviewRoute = `/management/${this.selectedTab}/${
          this.actionPdp?.id
        }/${
          this.availableTabs[
            this.availableTabs?.findIndex(
              (item) => item.id === TAB_TYPES.review.id
            )
          ]?.id
        }`;
        switch (true) {
          case this.isCurrentRole &&
            this.midYearSection?.complete &&
            reviewRoute &&
            this.$route.path !== reviewRoute: {
            await this.$router.push(reviewRoute);
            break;
          }
          case this.isCurrentRole &&
            this.planSection?.complete &&
            implementRoute &&
            this.$route.path !== implementRoute: {
            await this.$router.push(implementRoute);
            break;
          }
          default:
            await this.$router.push(firstRoute);
        }
      } else if (this.availableTabs && this.actionTab && this.showTabs) {
        await this.syncTabToRoute();
      }
    },
    handleBackButton() {
      this.$router.push(`/management/${this.selectedTab}`);
    },
    async handleSignedForm(sectionToBeUpdated, userType) {
      if (sectionToBeUpdated) {
        this.actionPdp.sections = signNTSSectionAsUserType(
          sectionToBeUpdated,
          this.actionPdp,
          userType,
          this.loggedInUser.id
        );
        this.gtmTrackSign(sectionToBeUpdated.sectionType);
        await this.saveActionPdp(this.actionPdp);
      }
    },
    gtmTrackSign(sectionType) {
      this.$gtm.trackEvent({
        event: null,
        category: 'SupervisorAction',
        action: 'Supervisor Sign Pdp',
        label: 'OrientationBanner',
        value: `${sectionType}`,
      });
    },
    async getNotes() {
      if (
        !this.personalNotes ||
        (this.personalNotes && this.personalNotes.pdp_id !== this.actionPdp.id)
      ) {
        await this.getPersonalNotes(this.actionPdp.id);
      }
    },
    async openNotes() {
      await this.getNotes();
      this.notesOpen = true;
      this.$emit('notesOpen', this.notesOpen);
    },
    async updateNotes() {
      await this.updatePersonalNotes(this.personalNotes);
      this.notesOpen = false;
      this.$emit('notesOpen', this.notesOpen);
    },
    async closeNotes() {
      this.notesOpen = false;
      this.$emit('notesOpen', this.notesOpen);
    },
    validateTabForm() {
      this.$refs.tabForm.validate();
    },
    isLoggedInUserOfType(userType) {
      const user = findPdpUserByType(
        this.actionPdp?.pdp_user_associations,
        userType
      );
      return user?.user_id === this.loggedInUser?.id;
    },
    closeExpiredPdpDialog() {
      this.$emit('handleRefresh');
    },
  },
  emits: ['handleRefresh'],
};
</script>

<style lang="scss" scoped>
// use pre-defined ADS scss variables
@import '../../scss/variables';

@media screen and (max-width: 959px) {
  :deep .v-slide-group .v-slide-group__prev {
    &--disabled {
      display: none !important;
    }
  }
  :deep .v-expansion-panel-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
