<template>
  <v-container class="px-0 pa-md-7">
    <v-row class="mx-0 flex-column">
      <v-col
        class="px-5 px-md-0 no-break-inside"
        md="11"
      >
        <v-container class="px-0 pb-4">
          <v-row class="mx-0">
            <v-col class="pa-0">
              <p>
                A self-assessment provides an opportunity to reflect on teaching
                and/or leadership practice, and on progress towards achievement
                of your PDP goals.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        class="px-5 px-md-0"
        md="11"
      >
        <v-container class="pa-0">
          <v-row align="start">
            <v-icon
              :color="ADS_Colors.Blue_2"
              class="mx-2"
            >
              mdi-clipboard-outline
            </v-icon>
            <v-col
              v-if="!midYearSection.complete"
              class="pa-0 no-break-inside"
            >
              <IncompleteFieldCard
                v-if="isIncompleteArchivedPdp && !formPdp?.midYearText"
              />
              <RichTextField
                v-else
                v-model="formPdp.midYearText"
                label="Reflect on your progress towards achieving your professional goals."
                :rules="[rules.stringFieldRequired]"
                :disabled="readOnly"
                lazy
              />
            </v-col>
            <v-col
              v-if="midYearSection.complete"
              class="pa-0 ml-2 mb-8 no-break-inside"
            >
              <div
                class="pa-0"
                v-dompurify-html="
                  formPdp.midYearText ? formPdp.midYearText : ''
                "
              />
            </v-col>
            <v-col
              v-if="!midYearSection.complete"
              class="text-center flex-shrink-1 flex-grow-0 flex-md-shrink-0"
              md="1"
            >
              <v-tooltip
                v-if="!readOnly"
                v-model="showTooltipMessage"
                bottom
                color="primary"
              >
                <template #activator="{ attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    @click="showTooltipMessage = !showTooltipMessage"
                  >
                    <v-icon
                      color="primary"
                      dark
                    >
                      help_outline
                    </v-icon>
                  </v-btn>
                </template>
                <ul class="ma-0 pa-2">
                  <li>Reflect on your teaching and/or leadership practice</li>
                  <li>Evaluate the impact of professional learning</li>
                  <li>
                    Comment on evidence you have collected to demonstrate your
                    progress
                  </li>
                  <li>
                    Identify any factors impacting your career development and
                    strategies for addressing these.
                  </li>
                </ul>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="mx-0 px-0 flex-column">
      <v-col
        v-if="midYearSection.supervisorSignature"
        class="px-0 px-md-0 no-break-inside"
      >
        <p class="title px-5 px-md-0 mt-5">
          <v-icon
            :color="ADS_Colors.Blue_2"
            class="mr-2"
          >
            mdi-message-reply-text-outline
          </v-icon>
          Comments
        </p>
        <v-row class="mx-0 flex-column">
          <v-col
            class="px-0 px-md-0 no-break-inside"
            md="11"
          >
            <v-card class="elevation-2 mt-2">
              <p
                class="px-5 pt-5 px-md-4 text-body-1"
                v-dompurify-html="
                  midYearSection.supervisorComment
                    ? midYearSection.supervisorComment
                    : 'No comments from PDP supervisor'
                "
              />
              <v-card-title
                class="px-5 px-md-4 comments text-md-h6"
                v-if="midYearSection.supervisorSignTime"
              >
                PDP Supervisor
                {{ formatName(midYearSupervisor) }}
                signed this section on
                {{ formatDateTime(midYearSection?.supervisorSignTime) }}
              </v-card-title>
            </v-card>
          </v-col>
          <v-col
            class="px-0 px-md-0 no-break-inside"
            md="11"
          >
            <v-card
              class="elevation-2 mt-2 no-break-inside"
              v-if="midYearSection.userSignature"
            >
              <p
                class="px-5 pt-5 px-md-4 text-body-1"
                v-dompurify-html="
                  midYearSection?.userComment
                    ? midYearSection?.userComment
                    : 'No comments from supervisee'
                "
              />
              <v-card-title
                class="px-5 px-md-4 comments text-md-h6"
                v-if="midYearSection?.userSignTime"
              >
                {{ formatName(loggedInUser) }} signed this section on
                {{ formatDateTime(midYearSection?.userSignTime) }}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        v-if="
          !midYearSection?.complete &&
          !(isPrint && isIncompleteArchivedPdp && !formPdp?.midYearText)
        "
        class="pl-5 pl-md-0"
        data-test="save-self-assessment"
      >
        <AdsButton
          v-if="!readOnly"
          buttonText="Save"
          :disabled="!isMidYearTextValid"
          secondary
          @click="save"
        />
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col
        class="px-0"
        md="11"
      >
        <v-card
          v-if="showNotifyBanner"
          class="pa-5"
        >
          <MarkComplete
            :section="midYearSection"
            :showBanner="showNotifyBanner"
            :showEditGoalsInfo="false"
            @onNotifyUser="handleNotifyUser"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import validators from '@/lib/validators';
import { formatDateTime, formatName, getFinalSection } from '@/utils.ts';
import { AdsButton } from '@nswdoe/doe-ui-core';
import MarkComplete from './MarkComplete';
import { mapGetters } from 'vuex';
import { clone } from 'ramda';
import RichTextField from '@/components/RichTextField';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard';
import { SECTION_TYPE, USER_TYPE } from '@/data/constants';

export default {
  name: 'TSProgressReport',
  components: {
    RichTextField,
    AdsButton,
    MarkComplete,
    IncompleteFieldCard,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      ADS_Colors,
      showTooltipMessage: false,
      rules: {
        stringFieldRequired: validators.stringFieldRequired,
      },
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'formPdp', 'pdp', 'isPdpArchived']),
    ...mapGetters('pdp', {
      formPdpUser: 'formPdpUser',
    }),
    sections() {
      return this.formPdp.sections;
    },
    midYearSection() {
      return this.formPdp.sections.find((s) => s.sectionType === 'MID_YEAR');
    },
    midYearSupervisor() {
      return this.formPdpUser(SECTION_TYPE.midYear, USER_TYPE.supervisor);
    },
    isMidYearTextValid() {
      return validators.stringFieldRequired(this.formPdp.midYearText) === true;
    },
    isMidYearTextSavedValid() {
      return validators.stringFieldRequired(this.pdp.midYearText) === true;
    },
    showNotifyBanner() {
      return (
        this.isMidYearTextSavedValid &&
        !this.midYearSection.complete &&
        !this.midYearSection.notifySupervisor
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isIncompleteArchivedPdp() {
      const finalSection = getFinalSection(this.formPdp?.sections);
      return this.isPdpArchived && !finalSection?.complete;
    },
  },
  methods: {
    formatName,
    formatDateTime,
    async handleNotifyUser() {
      const pdp = clone(this.formPdp);
      pdp.sections = pdp.sections.map((s) => {
        if (s.sectionType === 'MID_YEAR') {
          s.notifySupervisor = true;
          s.notifySupervisorTime = Date.now();
        }
        return s;
      });
      await this.$store.dispatch('pdp/savePdp', pdp);
      this.$vuetify.goTo(0);
    },
    async save() {
      const pdp = clone(this.formPdp);
      pdp.sections = pdp.sections.map((s) => {
        if (s.sectionType === 'MID_YEAR') {
          s.notifySupervisor = false;
          s.supervisorSignTime = null;
          s.supervisorSignature = null;
        }
        return s;
      });
      this.$store.dispatch('pdp/savePdp', pdp);
    },
  },
  inject: {
    isPrint: {
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 959px) {
  .comments {
    font-size: 16px;
    word-break: break-word;
  }
}
.no-break-inside {
  break-inside: avoid;
}
</style>
