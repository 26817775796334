<template>
  <tbody>
    <TSUserBannerPrint
      class="ts-user-banner--print"
      backgroundColour="white"
      :pdp="actionPdp"
      :user="actionPdp?.owner"
    />
    <!-- PLAN SECTION -->
    <v-container class="px-0 pb-5">
      <h1 class="display-1 black--text">Plan</h1>
    </v-container>
    <div v-if="canViewSection(TAB_TYPES.plan)">
      <TSPlanReadOnlyPrint
        :pdp="actionPdp"
        :section="planSection"
      />
    </div>
    <v-container
      v-else
      class="px-0"
    >
      <NoPermissionCard />
    </v-container>
    <!-- GOALS AND EVIDENCE -->
    <v-container class="px-0 py-5 page-break-before">
      <h1 class="display-1 black--text">Goals and evidence</h1>
    </v-container>
    <v-container
      v-if="canViewSection(TAB_TYPES.goalsAndEvidence)"
      class="pa-0"
    >
      <v-card
        v-if="midYearSection?.goals?.length"
        class="my-5"
      >
        <v-container
          class="px-0 px-md-10 py-5"
          fluid
        >
          <TSPdpGoalsPrint
            :editable="false"
            isSupervisor
            :owner="actionPdp?.owner"
            :section="midYearSection"
            :showEditToggle="false"
            :startEdit="false"
            :userIsPrincipal="userIsPrincipal"
            :goals="midYearSection?.goals"
            expandAll
          />
        </v-container>
      </v-card>
      <IncompleteFieldCard v-else />
    </v-container>
    <v-container
      v-else
      class="px-0"
    >
      <NoPermissionCard />
    </v-container>

    <!-- OBSERVATIONS -->
    <v-container class="px-0 page-break-before">
      <v-container class="pa-0 pt-5">
        <h1 class="display-1 black--text">Observations</h1>
      </v-container>
      <IncompleteFieldCard
        v-if="
          canViewSection(TAB_TYPES.observations) &&
          isIncompleteArchivedPdp &&
          actionPdp?.observations?.length === 0
        "
        class="mt-5"
      />
      <v-card
        v-else-if="canViewSection(TAB_TYPES.observations)"
        class="my-5 py-5 page-break"
      >
        <TSPdpObservationsPrint
          :pdp="actionPdp"
          :editMode="false"
        />
      </v-card>
      <NoPermissionCard
        v-else
        class="px-0"
      />
    </v-container>
    <!-- REVIEWS -->
    <v-container class="pa-0 page-break-before">
      <v-container class="pa-0 pt-5">
        <h1 class="display-1 black--text">Reviews</h1>
      </v-container>
      <div v-if="canViewSection(TAB_TYPES.reviews)">
        <Wrapper
          :isMobile="isMobile"
          cardClass="py-5 px-10"
          class="mt-8 pa-0"
        >
          <AdsExpansionPanel
            v-model="reviewPanels"
            :items="[
              { id: 1, title: 'Mid-Year Self-Assessment' },
              { id: 2, title: 'Annual review' },
            ]"
            multiple
          >
            <template #[`content1`]>
              <div
                v-if="isIncompleteArchivedPdp && !actionPdp?.midYearText"
                class="pa-5"
              >
                <IncompleteFieldCard />
              </div>
              <div v-else>
                <v-container class="pa-0 pa-md-5">
                  <v-card class="pa-5">
                    <v-card-title class="px-0 px-md-4 text-h6">
                      Review of progress
                    </v-card-title>
                    <div
                      class="px-0 px-md-4 text-body-1"
                      v-dompurify-html="
                        actionPdp?.midYearText ? actionPdp?.midYearText : ''
                      "
                    />
                    <p class="title mt-5">
                      <v-icon
                        :color="ADS_Colors.Blue_2"
                        class="mr-2"
                      >
                        mdi-message-reply-text-outline
                      </v-icon>
                      Comments
                    </p>
                    <v-row class="mx-n5 mx-md-n3 flex-column">
                      <v-col class="px-0 px-md-3">
                        <v-card
                          v-if="midYearSection?.supervisorSignature"
                          class="elevation-4 mx-0 mx-md-5 mt-2"
                        >
                          <div
                            class="px-5 pt-5 px-md-4 text-body-1"
                            v-dompurify-html="
                              midYearSection?.supervisorComment
                                ? midYearSection.supervisorComment
                                : '<p>No comments from PDP supervisor</p>'
                            "
                          />
                          <v-card-title
                            v-if="midYearSection?.supervisorSignature"
                            class="px-5 px-md-4 text-h6"
                          >
                            PDP Supervisor
                            {{ formatName(midYearSupervisor) }}
                            signed this section on
                            {{
                              formatDateTime(midYearSection?.supervisorSignTime)
                            }}
                          </v-card-title>
                        </v-card>
                      </v-col>
                      <v-col class="px-0 px-md-3">
                        <v-card
                          v-if="midYearSection?.userSignature"
                          class="elevation-4 mx-0 mx-md-5 mt-2"
                        >
                          <div
                            class="px-5 pt-5 px-md-4 text-body-1"
                            v-dompurify-html="
                              midYearSection?.userComment
                                ? midYearSection.userComment
                                : '<p>No comments from supervisee</p>'
                            "
                          />
                          <v-card-title
                            v-if="midYearSection.userSignTime"
                            class="px-5 px-md-4 text-h6"
                          >
                            {{ formatName(actionPdp?.owner) }}
                            signed this section on
                            {{ formatDateTime(midYearSection?.userSignTime) }}
                          </v-card-title>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-container>
                <v-card
                  v-if="!midYearSection?.complete"
                  class="pa-5"
                  flat
                >
                  <p class="ml-0 ml-md-5">
                    <strong>Mid Year Self-Assessment</strong>
                    has not yet been completed
                  </p>
                </v-card>
              </div>
            </template>
            <template #[`content2`]>
              <div v-if="isCurrentSupervisor || userIsSchoolPrincipal">
                <div
                  v-if="isIncompleteArchivedPdp && !actionPdp?.annualText"
                  class="pa-5"
                >
                  <IncompleteFieldCard />
                </div>
                <div v-else>
                  <v-container class="pa-0 pa-md-5">
                    <v-card class="pa-5">
                      <v-card-title class="px-0 px-md-4 text-h6">
                        Annual review
                      </v-card-title>
                      <div
                        class="px-0 px-md-4 text-body-1"
                        v-dompurify-html="
                          actionPdp?.annualText ? actionPdp.annualText : ''
                        "
                      />
                      <p class="title mt-5">
                        <v-icon
                          :color="ADS_Colors.Blue_2"
                          class="mr-2"
                        >
                          mdi-message-reply-text-outline
                        </v-icon>
                        Comments
                      </p>
                      <v-row class="mx-n5 mx-md-n3 flex-column">
                        <v-col class="px-0 px-md-3">
                          <v-card
                            v-if="finalSection?.supervisorSignature"
                            class="elevation-4 mx-0 mx-md-5 mt-2"
                          >
                            <div
                              class="px-5 pt-5 px-md-4 text-body-1"
                              v-dompurify-html="
                                finalSection?.supervisorComment
                                  ? finalSection?.supervisorComment
                                  : '<p>No comments from PDP supervisor</p>'
                              "
                            />
                            <v-card-title
                              v-if="finalSection?.supervisorSignTime"
                              class="px-5 px-md-4 text-h6"
                            >
                              PDP Supervisor
                              {{ formatName(finalSupervisor) }}
                              signed this section on
                              {{
                                formatDateTime(finalSection?.supervisorSignTime)
                              }}
                            </v-card-title>
                          </v-card>
                        </v-col>
                        <v-col class="px-0 px-md-3">
                          <v-card
                            v-if="finalSection?.userSignature"
                            class="elevation-4 mx-0 mx-md-5 mt-2"
                          >
                            <div
                              class="px-5 pt-5 px-md-4 text-body-1"
                              v-dompurify-html="
                                finalSection?.userComment
                                  ? finalSection?.userComment
                                  : '<p>No comments from supervisee</p>'
                              "
                            />
                            <v-card-title
                              v-if="finalSection?.userSignTime"
                              class="px-5 px-md-4 text-h6"
                            >
                              {{ formatName(actionPdp?.owner) }}
                              signed and archived this section on
                              {{ formatDateTime(finalSection?.userSignTime) }}
                            </v-card-title>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-container>
                  <v-card
                    v-if="!finalSection?.complete"
                    class="pa-5"
                    flat
                  >
                    <p class="ml-0 ml-md-5">
                      <strong>Annual review</strong>
                      has not yet been completed
                    </p>
                  </v-card>
                </div>
              </div>
              <v-container
                v-else
                class="pa-0 pa-md-5"
              >
                <NoPermissionCard />
              </v-container>
            </template>
          </AdsExpansionPanel>
        </Wrapper>
      </div>
      <NoPermissionCard
        v-else
        class="px-0"
      />
    </v-container>
    <!-- ARCHIVE REASON CARD -->
    <v-container class="pa-0 my-5 page-break-before">
      <IncompleteArchiveCard
        v-if="isIncompleteArchivedPdp"
        :pdp="actionPdp"
      />
    </v-container>
    <!-- PERSONAL NOTES -->
    <PersonalNotesCard
      class="mb-10 pa-0 page-break-before"
      :comments="supervisorNotes"
    />
  </tbody>
</template>

<script>
import { AdsExpansionPanel, ADS_Colors } from '@nswdoe/doe-ui-core';
import TSUserBannerPrint from '@/components/print/TSUserBannerPrint';
import TSPlanReadOnlyPrint from '@/components/print/TSPlanReadOnlyPrint';
import TSPdpGoalsPrint from '@/components/print/TSPdpGoalsPrint';
import TSPdpObservationsPrint from '@/components/print/TSPdpObservationsPrint';
import NoPermissionCard from '@/components/print/NoPermissionCard';
import PersonalNotesCard from '@/components/print/PersonalNotesCard';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard';
import IncompleteArchiveCard from '@/components/IncompleteArchiveCard';
import Wrapper from '@/components/Wrapper';
import { formatDateTime, formatName, getFinalSection } from '@/utils';
import { TAB_TYPES } from '@/data/constants';
import { includes } from 'ramda';
import { mapGetters } from 'vuex';
export default {
  name: 'TSSupervisorArchivedPrintContent',
  components: {
    AdsExpansionPanel,
    TSUserBannerPrint,
    TSPlanReadOnlyPrint,
    TSPdpGoalsPrint,
    TSPdpObservationsPrint,
    NoPermissionCard,
    PersonalNotesCard,
    IncompleteFieldCard,
    IncompleteArchiveCard,
    Wrapper,
  },
  props: {
    actionPdp: {
      type: Object,
      default: () => ({}),
    },
    planSection: {
      type: Object,
      default: () => ({}),
    },
    midYearSection: {
      type: Object,
      default: () => ({}),
    },
    finalSection: {
      type: Object,
      default: () => ({}),
    },
    midYearSupervisor: {
      type: Object,
      default: () => ({}),
    },
    finalSupervisor: {
      type: Object,
      default: () => ({}),
    },
    isCurrentSupervisor: {
      type: Boolean,
      required: true,
    },
    isPlanSupervisor: {
      type: Boolean,
      required: true,
    },
    isMidYearSupervisor: {
      type: Boolean,
      required: true,
    },
    selectedTab: {
      type: String,
      required: true,
    },
    owner: {
      type: Object,
      default: () => {
        return {};
      },
    },
    userIsPrincipal: {
      type: Boolean,
      required: true,
    },
    supervisorNotes: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      ADS_Colors,
      TAB_TYPES,
      tabs: [
        TAB_TYPES.plan,
        TAB_TYPES.goalsAndEvidence,
        TAB_TYPES.observations,
        TAB_TYPES.reviews,
      ],
      reviewPanels: [0, 1],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    availableTabs() {
      return [
        ...(this.isCurrentSupervisor ||
        this.isPlanSupervisor ||
        this.userIsSchoolPrincipal
          ? [TAB_TYPES.plan]
          : []),
        ...(this.isCurrentSupervisor ||
        this.isMidYearSupervisor ||
        this.userIsSchoolPrincipal
          ? [
              TAB_TYPES.goalsAndEvidence,
              TAB_TYPES.observations,
              TAB_TYPES.reviews,
            ]
          : []),
      ];
    },
    userIsSchoolPrincipal() {
      return (
        (this.selectedTab === TAB_TYPES.school.id ||
          this.selectedTab === TAB_TYPES.archived.id) &&
        this.loggedInUser?.isPrincipal &&
        this.loggedInUser?.schoolCode ===
          this.actionPdp?.owner_school?.schoolCode
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isIncompleteArchivedPdp() {
      const finalSection = getFinalSection(this.actionPdp?.sections);
      return !this.actionPdp?.active && !finalSection?.complete;
    },
  },
  methods: {
    canViewSection(tab) {
      return includes(tab, this.availableTabs);
    },
    formatName,
    formatDateTime(date) {
      return formatDateTime(date);
    },
  },
  provide: {
    isPrint: true,
  },
};
</script>

<style scoped lang="scss">
.page-break-before {
  break-before: page;
}
.v-tabs {
  pointer-events: none;
}
.ts-user-banner--print {
  &.banner1 {
    color: $ads-grey-01;
    :deep .v-icon {
      color: $ads-grey-01;
    }
  }
}
:deep .v-expansion-panel {
  break-inside: avoid;
}
:deep .goal-heading {
  background-color: $ads-secondary-grey;
}
:deep .v-icon {
  color: $ads-grey-01 !important;
}
</style>
