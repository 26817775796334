<template>
  <v-container
    v-if="showBanner"
    class="mt-0 px-4 px-md-0"
  >
    <Alert
      id="orientation"
      :border="isMobile ? 'top' : 'left'"
      :icon="getAlertMessage.icon ? getAlertMessage.icon : 'mdi-check-circle'"
      :type="getAlertMessage.isSigningBanner ? 'info' : 'success'"
      inPage
      :alertClass="
        getAlertMessage.isSigningBanner
          ? 'info-background-banner'
          : 'success-background-banner'
      "
    >
      <template #text>
        <v-container class="ma-0 pa-0">
          <v-row>
            <v-col :cols="bannerContentWidth">
              <v-row
                align="end"
                class="flex-column flex-md-row"
                :class="getAlertMessage.isActionBanner ? 'pb-4' : ''"
              >
                <v-col class="pt-3">
                  <p
                    class="subtitle-2"
                    v-if="getAlertMessage.phase"
                  >
                    {{ getAlertMessage.phase }}
                  </p>
                  <p class="title">
                    {{ getAlertMessage.heading }}
                  </p>
                  <template v-for="para in getAlertMessage.text">
                    <p
                      v-html="para"
                      class="title-2 mb-0 negative-margin-left"
                      :key="para"
                    ></p>
                  </template>
                  <template v-for="(link, index) in getAlertMessage.links">
                    <p :key="link.text">
                      <v-icon
                        class="mr-2"
                        v-if="getAlertMessage.linksNumbered"
                      >
                        {{ getLook(index) }}
                      </v-icon>
                      <a :href="link.href">{{ link.text }}</a>
                    </p>
                  </template>
                </v-col>
                <v-col
                  :md="getAlertMessage.hasCommentBox && '4'"
                  class="d-flex flex-grow-0 justify-end d-md-block text-md-center"
                  v-if="getAlertMessage.isActionBanner"
                >
                  <AdsButton
                    class="success-button"
                    icon="mdi-thumb-up-outline"
                    :buttonText="
                      getAlertMessage.buttonText
                        ? getAlertMessage.buttonText
                        : 'Sign'
                    "
                    @click="startSigning"
                  />
                </v-col>
              </v-row>
              <template v-if="getAlertMessage.isSigningBanner">
                <v-container class="px-0 px-md-3">
                  <v-row
                    class="flex-column flex-md-row flex-grow-0 align-md-end justify-md-space-between pb-4 negative-margin-left"
                  >
                    <v-col
                      :md="getAlertMessage.hasCommentBox && '3'"
                      class="d-flex flex-row flex-md-shrink-0 flex-md-grow-0 flex-md-column justify-space-between align-center align-md-start px-0 pr-3 pr-md-0 pt-6 pt-md-3"
                    >
                      <div v-if="!isSupervisor">
                        <p class="ma-0 mb-2">
                          Supervisee's name
                          <br />
                          <strong>{{ userName }}</strong>
                        </p>
                      </div>
                      <div v-else-if="isSupervisor">
                        <p class="ma-0">
                          <strong>{{ supervisorName }}</strong>
                        </p>
                        <p class="my-0 mb-sm-4">PDP Supervisor's name</p>
                      </div>
                      <div>
                        <v-btn
                          class="confirm-box d-flex column align-content-start text-left"
                          outlined
                          @click="confirm"
                          background="white"
                        >
                          <v-checkbox
                            class=""
                            :input-value="tickedConfirm"
                            data-test="supervisee-checkbox"
                          />
                          I confirm
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col
                      v-if="getAlertMessage.hasCommentBox"
                      :md="getAlertMessage.hasCommentBox && '6'"
                      class="flex-row justify-center px-0 pr-3 px-md-3"
                    >
                      <RichTextField
                        :background-color="'#FFFFFF'"
                        v-model="comment"
                        label="Optional comments"
                        lazy
                        style="background-color: #ffffff"
                        class="mt-2"
                        :placeholder="readOnly ? null : 'Type here'"
                        :disabled="readOnly"
                      />
                    </v-col>
                    <v-col
                      :md="getAlertMessage.hasCommentBox && '3'"
                      class="flex-md-grow-0 justify-end text-right pb-3"
                    >
                      <AdsButton
                        :icon="getAlertIcon"
                        @click="$emit('signed', section, comment)"
                        :disabled="!tickedConfirm"
                        :buttonText="
                          getAlertMessage.buttonText
                            ? getAlertMessage.buttonText
                            : 'Submit'
                        "
                        data-test="sign-pdp"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </Alert>
  </v-container>
</template>

<script>
import { Alert, AdsButton } from '@nswdoe/doe-ui-core';
import {
  formatName,
  getOrientationBannerMessageForSupervisee,
  getOrientationBannerMessageForSupervisor,
} from '@/utils';
import { mapState } from 'vuex';
import { SECTION_TYPE } from '@/data/constants';
import RichTextField from '@/components/RichTextField.vue';

export default {
  name: 'OrientationBanner',
  props: {
    pdp: {
      type: Object,
      default: null,
    },
    section: {
      type: Object,
      default: null,
    },
    isSupervisor: {
      type: Boolean,
      default: false,
    },
    supervisorName: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: true,
    },
    asSupervisor: {
      type: Boolean,
      default: false,
    },
    isSigningPrintBanner: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RichTextField,
    Alert,
    AdsButton,
  },
  data() {
    return {
      tickedConfirm: false,
      comment: '',
      isSigningActionBanner: false,
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.loggedInUser.id,
    }),
    userName() {
      return formatName(this.pdp?.owner);
    },
    isPrincipal() {
      return this.pdp?.owner?.isPrincipal;
    },
    userComments() {
      return this.pdp?.userComments ?? '';
    },
    sections() {
      return this.pdp?.sections;
    },
    planSection() {
      return this.pdp?.sections.find(
        (s) => s.sectionType === SECTION_TYPE.plan
      );
    },
    midYearSection() {
      return this.pdp?.sections.find(
        (s) => s.sectionType === SECTION_TYPE.midYear
      );
    },
    finalSection() {
      return this.pdp?.sections.find(
        (s) => s.sectionType === SECTION_TYPE.final
      );
    },
    getAlertMessage() {
      if (this.isSupervisor) {
        return getOrientationBannerMessageForSupervisor(
          this.pdp,
          this.sections,
          this.isSigningBanner,
          this.userName
        );
      } else {
        return getOrientationBannerMessageForSupervisee(
          this.sections,
          this.isSigningBanner,
          this.userName
        );
      }
    },
    getAlertIcon() {
      return this.getAlertMessage.buttonText === 'Sign & Archive'
        ? 'archive'
        : 'mdi-send-outline';
    },
    showBanner() {
      if (this.getAlertMessage.heading === "I'm message zero") return false;
      else return this.show;
    },
    bannerContentWidth() {
      if (
        !this.getAlertMessage.isActionBanner &&
        !this.getAlertMessage.isSigningBanner
      ) {
        return 11;
      } else {
        return 12;
      }
    },
    isSigningBanner() {
      if (this.readOnly) {
        return this.isSigningPrintBanner;
      } else {
        return this.isSigningActionBanner;
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    confirm() {
      this.tickedConfirm = !this.tickedConfirm;
    },
    getLook(index) {
      let lookIcon = 'looks_';

      switch (index) {
        case 0:
          lookIcon += 'one';
          break;
        case 1:
          lookIcon += 'two';
          break;
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
          lookIcon += `${index + 1}`;
          break;
      }

      return lookIcon;
    },
    startSigning() {
      this.isSigningActionBanner = true;
      this.$emit('startSigning');
    },
  },
  emits: ['startSigning', 'signed'],
};
</script>

<style scoped lang="scss">
.v-application .primary.success-button {
  background-color: $ads-success-green !important;
  border-color: $ads-success-green !important;
}

:deep .confirm-box {
  background-color: white;
  border: $ads-navy-dark-1 solid 2px !important;
}

.confirm-text {
  color: $ads-navy-dark-1;
}

:deep .info-background-banner {
  background-color: rgba(108, 172, 228, 0.2) !important;
  border: none !important;
  // colour for info banner text
  &.theme--light.v-sheet {
    color: $ads-grey-01;
  }
}

:deep .success-background-banner {
  background-color: rgba(168, 237, 179, 0.4) !important;
  border: none !important;
}

@media screen and (max-width: 959px) {
  .negative-margin-left {
    margin-left: -43px;
  }
  :deep .v-alert {
    &.info-background-banner {
      background-color: $ads-white !important;
      .v-alert__icon {
        color: $ads-secondary-blue !important;
      }
      .v-alert__border--top {
        background-color: $ads-secondary-blue !important;
        border-color: $ads-secondary-blue !important;
      }
    }
    .v-alert__wrapper {
      margin-top: 16px;
    }
    &__border--top {
      border-width: 3px !important;
    }
  }
}
</style>
