import {
  Goal,
  LearningTheme,
  LearningThemeType,
  Pdp,
  PdpUser,
  School,
  Section,
  SectionLearningTheme,
  SectionType,
} from '@/schema';
import { PDP_TYPE, SECTION_TYPE, USER_TYPE } from '@/data/constants';
import { clone } from 'ramda';
import { Generated } from 'kysely';

export const findPdpUserByType = (
  pdpUsers: PdpUser[],
  pdpUserType: string,
  active = true
) => {
  if (!pdpUsers || pdpUsers.length === 0) {
    return null;
  }
  const activeUser = pdpUsers.find(
    (pdpUser) => pdpUser.type === pdpUserType && pdpUser.active === active
  );
  return activeUser ?? null;
};

export const findAllPdpUserByType = (
  pdpUsers: PdpUser[],
  pdpUserType: string
) => {
  if (!pdpUsers || pdpUsers.length === 0) {
    return null;
  }
  const user = pdpUsers.filter((pdpUser) => pdpUser.type === pdpUserType);
  return user ?? null;
};

export const findPdpUserByUserId = (
  pdpUsers: PdpUser[],
  userId: Generated<number> | number,
  userType: string
) => {
  const sortedUsers = pdpUsers;
  sortedUsers.sort(
    (a, b) => (b.id as unknown as number) - (a.id as unknown as number)
  );
  const activeUser = sortedUsers
    ?.filter((user) => user.active)
    ?.find(
      (user) =>
        (user.user_id as unknown as number) === (userId as unknown as number) &&
        user.type === userType
    );
  if (activeUser) {
    return activeUser;
  } else {
    return sortedUsers
      ?.filter((user) => !user.active)
      ?.find(
        (user) =>
          (user.user_id as unknown as number) ===
            (userId as unknown as number) && user.type === userType
      );
  }
};

export const findUserBySection = (
  pdp: Pdp,
  sectionType: SectionType,
  userType: string
) => {
  const section = findSectionByType(pdp, sectionType);
  const users = findAllPdpUserByType(pdp.pdp_user_associations, userType);
  let userId: number | null | undefined;
  switch (userType) {
    case USER_TYPE.supervisor:
      userId = section?.supervisor_id;
      break;
    case USER_TYPE.pdpPrincipal:
      userId = section?.principal_id;
      break;
    case USER_TYPE.additionalSupervisor:
      userId = section?.manager_id;
      break;
  }
  if (!userId) {
    return null;
  }
  return users?.find((pdpUser) => userId === pdpUser.user_id)?.user;
};

export const findSectionByType = (pdp: Pdp, sectionType: SectionType) => {
  return pdp?.sections?.find((section) => section.sectionType === sectionType);
};

export const getLearningThemes = (
  sectionLearningThemes: SectionLearningTheme[],
  lookupLearningThemes: LearningTheme[]
) => {
  return sectionLearningThemes
    .filter((theme) => !theme.markForDelete)
    .map((sectionLearningTheme: SectionLearningTheme) =>
      lookupLearningThemes.find(
        (lookupTheme) =>
          sectionLearningTheme.learningtheme_id ===
          (lookupTheme.id as unknown as number)
      )
    );
};

export const setLearningThemes = (
  section: Section,
  selectedThemes: LearningTheme[],
  type: LearningThemeType
) => {
  const sectionLearningThemes =
    section.section_learning_theme_associations ?? [];
  const themesToSave: SectionLearningTheme[] = sectionLearningThemes.map(
    (sectionLearningTheme: SectionLearningTheme) => ({
      ...sectionLearningTheme,
      markForDelete: !selectedThemes.some(
        (selectedTheme: LearningTheme) =>
          (selectedTheme.id as unknown as number) ===
          sectionLearningTheme.learningtheme_id
      ),
    })
  );
  selectedThemes.forEach((selectedTheme) => {
    if (
      !sectionLearningThemes?.some(
        (sectionLearningTheme) =>
          (selectedTheme.id as unknown as number) ===
          sectionLearningTheme.learningtheme_id
      )
    ) {
      themesToSave.push({
        learningtheme_id: selectedTheme.id as unknown as number,
        section_id: section.id as unknown as number,
        type: type,
        active: true,
      } as unknown as SectionLearningTheme);
    }
  });
  return themesToSave;
};

export const copyNTSPlanToMidSection = (planSection: Section, formPdp: Pdp) => {
  formPdp.sections = formPdp.sections?.map((section) => {
    const isMidYearSection = section.sectionType === SECTION_TYPE.midYear;
    if (isMidYearSection && section.goals && section.goals.length === 0) {
      section.section_learning_theme_associations =
        planSection.section_learning_theme_associations?.map(
          (workLearningTheme) =>
            ({
              ...workLearningTheme,
              id: null,
              section_id: section.id as unknown as number,
            }) as unknown as SectionLearningTheme
        ) as SectionLearningTheme[];
      section.goals = planSection.goals?.map((goal: Goal) => {
        return {
          ...goal,
          id: null,
          section_id: section.id as unknown as number,
        };
      }) as unknown as Goal[];
    }
    section.workLearningText = planSection.workLearningText;
    section.careerLearningText = planSection.careerLearningText;
    return section;
  });
  return formPdp;
};

export const completeSection = (sectionToBeUpdated: Section, formPdp: Pdp) => {
  formPdp.sections = formPdp.sections?.map((section) => {
    if (section.sectionType === sectionToBeUpdated.sectionType) {
      section.complete = true;
    }
    return section;
  });
  return formPdp;
};

export const hasUserTypeSigned = (section: Section, userType: string) => {
  switch (userType) {
    case USER_TYPE.owner:
      return !!section?.userSignTime && !!section?.userSignature;
    case USER_TYPE.supervisor:
      return (
        section &&
        !!section?.supervisorSignTime &&
        !!section?.supervisorSignature
      );
    case USER_TYPE.pdpPrincipal:
      return !!section?.principalSignTime && !!section?.principalSignature;
    default:
      return false;
  }
};

export const notifySupervisor = (section: Section) => {
  section.notifySupervisor = true;
  section.notifySupervisorTime = Date.now();
  return section;
};

export const updateSections = (section: Section, sections: Section[]) => {
  return sections?.map((pdpSection) => {
    if (pdpSection.id === section?.id) {
      return section;
    }
    return pdpSection;
  });
};

export const updateGoals = (goal: Goal, section: Section) => {
  return section.goals?.map((sectionGoal) => {
    if (sectionGoal.id === goal?.id) {
      return goal;
    }
    return sectionGoal;
  });
};

export const signNTSSectionAsUserType = (
  sectionToBeUpdated: Section,
  pdp: Pdp,
  userType: string,
  userId: number
) => {
  let clonedPdp = clone(pdp);
  clonedPdp.sections?.map((section) => {
    if (section.sectionType === sectionToBeUpdated.sectionType) {
      switch (userType) {
        case USER_TYPE.supervisor:
          section.supervisor_id = userId;
          section.supervisorSignature = true;
          section.supervisorSignTime = Date.now();
          break;
        case USER_TYPE.pdpPrincipal:
          section.principal_id = userId;
          section.principalSignature = true;
          section.principalSignTime = Date.now();
          break;
        case USER_TYPE.owner:
          section.userSignature = true;
          section.userSignTime = Date.now();
          break;
        default:
          break;
      }
    }
    return section;
  });
  const updatedSection = findSectionByType(
    clonedPdp,
    sectionToBeUpdated.sectionType
  ) as Section;
  if (
    (userType === USER_TYPE.supervisor || userType === USER_TYPE.owner) &&
    hasUserTypeSigned(updatedSection, USER_TYPE.owner) &&
    hasUserTypeSigned(updatedSection, USER_TYPE.supervisor)
  ) {
    if (sectionToBeUpdated.sectionType === SECTION_TYPE.plan) {
      clonedPdp = copyNTSPlanToMidSection(sectionToBeUpdated, clonedPdp);
    }
    clonedPdp = completeSection(sectionToBeUpdated, clonedPdp);
  }
  return clonedPdp.sections;
};

export const resetNotification = (sectionToBeUpdated: Section) => {
  sectionToBeUpdated.notifySupervisor = false;
  sectionToBeUpdated.notifySupervisorTime = null;
  return sectionToBeUpdated;
};

export const resetUserSignature = (sectionToBeUpdated: Section) => {
  sectionToBeUpdated.userSignTime = null;
  sectionToBeUpdated.userSignature = false;
  return sectionToBeUpdated;
};

export const resetSupervisorSignature = (sectionToBeUpdated: Section) => {
  sectionToBeUpdated.supervisor_id = null;
  sectionToBeUpdated.supervisorSignTime = null;
  sectionToBeUpdated.supervisorSignature = false;
  return sectionToBeUpdated;
};

export const resetPrincipalSignature = (sectionToBeUpdated: Section) => {
  sectionToBeUpdated.principal_id = null;
  sectionToBeUpdated.principalSignTime = null;
  sectionToBeUpdated.principalSignature = false;
  return sectionToBeUpdated;
};

export const resetSignatures = (sectionType: SectionType, pdp: Pdp) => {
  const clonedPdp = clone(pdp);
  let updatedSection = findSectionByType(clonedPdp, sectionType) as Section;
  updatedSection = resetUserSignature(updatedSection);
  updatedSection = resetSupervisorSignature(updatedSection);
  if (clonedPdp.type === PDP_TYPE.nts) {
    updatedSection = resetPrincipalSignature(updatedSection);
  }
  return updateSections(updatedSection, clonedPdp.sections);
};

export const setNTSNotifySupervisor = (
  sectionType: SectionType,
  pdp: Pdp,
  notify = true
) => {
  const clonedPdp = clone(pdp);
  let updatedSection = findSectionByType(clonedPdp, sectionType) as Section;
  updatedSection = notify
    ? notifySupervisor(updatedSection)
    : resetNotification(updatedSection);
  updatedSection = resetUserSignature(updatedSection);
  updatedSection = resetSupervisorSignature(updatedSection);
  updatedSection =
    clonedPdp.type === PDP_TYPE.nts
      ? resetPrincipalSignature(updatedSection)
      : updatedSection;
  return updateSections(updatedSection, clonedPdp.sections);
};

export const newPdpUser = (
  pdpId: number,
  userId: number,
  userType: string,
  location?: string,
  position?: string
) => {
  return {
    pdp_id: pdpId,
    user_id: userId,
    location:
      userType === USER_TYPE.supervisor ||
      userType === USER_TYPE.additionalSupervisor
        ? location
        : null,
    position:
      userType === USER_TYPE.supervisor ||
      userType === USER_TYPE.additionalSupervisor
        ? position
        : null,
    active: true,
    type: userType,
  };
};

export const setUserData = (
  pdp: Pdp,
  pdpUserDetails: {
    userType: string;
    userId: number;
    school_id?: number;
    position?: string;
  }[],
  ownerDetails: {
    position: string;
    school: School;
  }
) => {
  const newPdp = clone(pdp);
  newPdp.pdp_user_associations?.sort(
    (a, b) => (b.id as unknown as number) - (a.id as unknown as number)
  );
  newPdp.userPosition = ownerDetails.position;
  newPdp.owner_school_id = ownerDetails.school?.id as unknown as number;
  for (const pdpUser of pdpUserDetails) {
    const currentPdpUser = findPdpUserByType(
      newPdp.pdp_user_associations,
      pdpUser.userType
    );

    const newPdpUser = findPdpUserByUserId(
      newPdp.pdp_user_associations,
      pdpUser.userId,
      pdpUser.userType
    );

    // no current user but selected user
    if (!currentPdpUser && pdpUser.userId) {
      newPdp.pdp_user_associations.push({
        pdp_id: newPdp.id as unknown as number,
        user_id: pdpUser.userId,
        school_id:
          pdpUser.userType === USER_TYPE.supervisor ||
          pdpUser.userType === USER_TYPE.additionalSupervisor
            ? pdpUser.school_id
            : null,
        position:
          pdpUser.userType === USER_TYPE.supervisor ||
          pdpUser.userType === USER_TYPE.additionalSupervisor
            ? pdpUser.position
            : null,
        active: true,
        type: pdpUser.userType,
      } as PdpUser);
      // current user but no selected user
    } else if (
      currentPdpUser &&
      !pdpUser.userId &&
      (pdpUser.userType === USER_TYPE.pdpPrincipal ||
        pdpUser.userType === USER_TYPE.additionalSupervisor)
    ) {
      currentPdpUser.active = false;
      if (
        currentPdpUser.type === USER_TYPE.pdpPrincipal &&
        currentPdpUser.notes === null &&
        newPdp.pdpPrincipalComments
      ) {
        currentPdpUser.notes = newPdp.pdpPrincipalComments;
        newPdp.pdpPrincipalComments = null;
      }
      newPdp.sections = newPdp.sections?.map((section) => {
        if (!section.complete) {
          switch (pdpUser?.userType) {
            case USER_TYPE.pdpPrincipal:
              section = resetPrincipalSignature(section);
              break;
            default:
              break;
          }
        }
        return section;
      });
    }
    // current user same as selected user, update details
    else if (
      currentPdpUser &&
      pdpUser.userId &&
      currentPdpUser?.user_id === pdpUser.userId &&
      currentPdpUser.type !== USER_TYPE.pdpPrincipal
    ) {
      if (currentPdpUser.school_id !== pdpUser.school_id) {
        currentPdpUser.school_id = pdpUser.school_id;
      }
      if (currentPdpUser.position !== pdpUser.position) {
        currentPdpUser.position = pdpUser.position;
      }
      switch (pdpUser?.userType) {
        case USER_TYPE.supervisor:
          if (currentPdpUser.notes === null && newPdp.supervisorComments) {
            currentPdpUser.notes = newPdp.supervisorComments;
          }
          newPdp.supervisorComments = null;
          break;
        case USER_TYPE.pdpPrincipal:
          if (currentPdpUser.notes === null && newPdp.pdpPrincipalComments) {
            currentPdpUser.notes = newPdp.pdpPrincipalComments;
          }
          newPdp.pdpPrincipalComments = null;
          break;
        default:
          break;
      }
    }
    // current user not the same as selected user
    else if (
      currentPdpUser &&
      pdpUser.userId &&
      currentPdpUser?.user_id !== pdpUser.userId
    ) {
      currentPdpUser.active = false;
      switch (pdpUser?.userType) {
        case USER_TYPE.supervisor:
          if (currentPdpUser.notes === null && newPdp.supervisorComments) {
            currentPdpUser.notes = newPdp.supervisorComments;
          }
          newPdp.supervisorComments = null;
          break;
        case USER_TYPE.pdpPrincipal:
          if (currentPdpUser.notes === null && newPdp.pdpPrincipalComments) {
            currentPdpUser.notes = newPdp.pdpPrincipalComments;
          }
          newPdp.pdpPrincipalComments = null;
          break;
        default:
          break;
      }
      if (newPdpUser?.user_id === pdpUser.userId && !newPdpUser.active) {
        newPdpUser.active = true;
      } else {
        newPdp.pdp_user_associations.push({
          pdp_id: newPdp.id as unknown as number,
          user_id: pdpUser.userId,
          school_id:
            pdpUser.userType === USER_TYPE.supervisor ||
            pdpUser.userType === USER_TYPE.additionalSupervisor
              ? pdpUser.school_id
              : null,
          position:
            pdpUser.userType === USER_TYPE.supervisor ||
            pdpUser.userType === USER_TYPE.additionalSupervisor
              ? pdpUser.position
              : null,
          active: true,
          type: pdpUser.userType,
        } as PdpUser);
      }
      newPdp.sections = newPdp.sections?.map((section) => {
        if (!section.complete) {
          switch (pdpUser?.userType) {
            case USER_TYPE.supervisor:
              section.supervisorComment = null;
              section = resetSupervisorSignature(section);
              section = resetNotification(section);
              break;
            case USER_TYPE.pdpPrincipal:
              section = resetPrincipalSignature(section);
          }
        }
        return section;
      });
    }
  }
  return newPdp;
};

export const handleAddNewGoal = (
  sectionType: SectionType,
  type: string,
  pdp: Pdp
) => {
  const clonedPdp = clone(pdp);
  const updatedSection = findSectionByType(clonedPdp, sectionType) as Section;
  updatedSection.goals?.push({
    index: updatedSection.goals?.length + 1,
    section_id: updatedSection.id as unknown as number,
    type: type,
  } as unknown as Goal);
  return updateSections(updatedSection, clonedPdp.sections);
};

export const handleDeleteGoal = (
  sectionType: SectionType,
  goal: Goal,
  pdp: Pdp
) => {
  const clonedPdp = clone(pdp);
  const clonedGoal = clone(goal);
  const updatedSection = findSectionByType(clonedPdp, sectionType) as Section;
  clonedGoal.markForDelete = true;
  updatedSection.goals = updateGoals(clonedGoal, updatedSection);
  return updateSections(updatedSection, clonedPdp.sections);
};

export const finalisePdp = (pdp: Pdp) => {
  pdp.finalCommentLocked = true;
};

export const archivePdp = (pdp: Pdp) => {
  pdp.active = false;
  return pdp;
};

export const getIsPdpIncompleteArchived = (pdp: Pdp | undefined) => {
  if (pdp) {
    const finalSection = findSectionByType(pdp, SECTION_TYPE.final);
    return (
      pdp?.active === false &&
      !finalSection?.complete &&
      (pdp?.type === PDP_TYPE.nts ? pdp?.finalCommentLocked == true : true)
    );
  } else return undefined;
};

export const getPdpSectionOnArchival = (pdp: Pdp | undefined) => {
  if (!pdp) {
    return undefined;
  }

  const planSection = findSectionByType(pdp, SECTION_TYPE.plan);
  const midYearSection = findSectionByType(pdp, SECTION_TYPE.midYear);
  const finalSection = findSectionByType(pdp, SECTION_TYPE.final);

  if (!planSection || !midYearSection || !finalSection) {
    return undefined;
  }

  if (!pdp.active) {
    if (!planSection.complete) {
      return planSection;
    } else if (!midYearSection.complete) {
      return midYearSection;
    } else {
      return finalSection;
    }
  }
};
