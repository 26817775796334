<template>
  <div>
    <Banner
      heading="Useful links"
      subtitle="Useful links for non-teaching staff"
      icon="mdi-link"
      defaultBackground1
    />
    <div class="container-wrapper">
      <v-tabs
        v-model="tab"
        class="ma-0 pa-0"
        slider-color="red"
      >
        <v-tab
          v-for="(item, index) in tabs"
          :key="`tab${index + 1}`"
        >
          {{ item.name }}
        </v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item key="tabItem1">
            <v-container
              class="container-main py-10 px-8"
              fluid
            >
              <TilesSection
                v-for="section in ntsSections"
                :key="section.title"
                :sectionTitle="section.title"
                :tiles="section.tiles"
              />
            </v-container>
          </v-tab-item>
          <v-tab-item key="tabItem2">
            <v-container
              class="container-main py-10 px-8"
              fluid
            >
              <TilesSection
                v-for="section in supervisorsSections"
                :key="section.title"
                :sectionTitle="section.title"
                :tiles="section.tiles"
              />
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { Banner } from '@nswdoe/doe-ui-core';
import TilesSection from './TilesSection.vue';

const performanceAndDevelopmentSectionContent = {
  title: 'Performance and development process',
  tiles: [
    {
      title: 'Performance and development for NTS',
      url: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/performance-and-development-process',
      description:
        'This simple process has three phases: plan, implement and review. Each phase is supported by a key conversation with your performance and development plan (PDP) supervisor.',
    },
    {
      title: 'Policy and procedure',
      url: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/policies-and-procedures',
      description:
        "The department's performance management and development policy and procedure apply to all non-teaching staff in schools, including...",
    },
    {
      title: 'Performance and development benefits for NTS',
      url: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools',
      description:
        'The performance and development process for non-teaching staff in schools supports our commitment to developing a skilled, engaged and professional workforce to ensure improved outcomes for our students.',
    },
    {
      title: 'Digital PDP for non-teaching staff in schools',
      url: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/performance-tools/digital-pdp-for-non-teaching-staff-in-schools',
      description:
        'Non-teaching staff can choose to opt in and use the Digital PDP from 2023...',
    },
    {
      title: 'Goal-setting for non-teaching staff',
      url: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/goal-setting-for-non-teaching-staff',
      description:
        'In the first phase of the performance and development process we plan for the year by setting meaningful work goals...',
    },
    {
      title: 'Meaningful conversations',
      url: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/meaningful-conversations',
      description:
        'Meaningful conversations between a staff member and PDP supervisor are essential to the success of the performance and development process. Meaningful conversations help us all to be our best...',
    },
    {
      title: 'Planning your PDP Playbook',
      url: 'https://education.nsw.gov.au/content/dam/main-education/inside-the-department/human-resources/media/documents/performance/non-teaching-staff-performance-and-development/NTS-Planning_your-PDP-Playbook.pdf',
      description:
        'This interactive guide has been designed to support staff in planning their PDP.',
    },
  ],
};
const professionalLearningSectionContent = {
  title: 'Professional learning',
  tiles: [
    {
      title: 'Learning Centre',
      url: 'https://schoolsnsw.sharepoint.com/sites/CorporateLearningCentre/SitePages/Welcome-to-the-Corporate-Learning-Centre.aspx',
      description:
        'The Learning Centre (LC) is a centralised digital learning platform on Sharepoint (a ‘one stop shop’) that has learning opportunities and development resources, for DoE staff...',
    },
    {
      title: 'Learning Catalogue',
      url: 'https://schoolsnsw.sharepoint.com/sites/CorporateLearningCentre/SitePages/Digital-Learning-Catalogue.aspx',
      description:
        'The learning catalogue houses all current learning solutions offered by the Learning Unit...',
    },
    {
      title: 'Health, safety and staff wellbeing training and induction',
      url: 'https://education.nsw.gov.au/inside-the-department/health-and-safety/training-and-induction',
      description:
        'Provides a range of e-Learning and practical training to support schools and workplaces....',
    },
    {
      title: 'EDConnect training and coaching',
      url: 'https://education.nsw.gov.au/inside-the-department/edconnect/about-us/training-and-coaching',
      description:
        'EDConnect offers support and guidance to school-based staff in operational processes...',
    },
    {
      title: 'Department of Education induction hub',
      url: 'https://education.nsw.gov.au/inside-the-department/induction',
      description:
        'Welcome to your induction hub. All your induction information, resources and training can be accessed here...',
    },
  ],
};
const roleResponsibilitiesSectionContent = {
  title: 'Role responsibilities',
  tiles: [
    {
      title: 'SAS staff - statements of duties',
      url: 'https://education.nsw.gov.au/industrial-relations/a-z-of-industrial-relations-topics/SASS-statements-of-duties',
      description:
        'Statement of duties for School Administrative and Support Staff roles...',
    },
    {
      title: 'School-based role description library',
      url: 'https://education.nsw.gov.au/inside-the-department/human-resources/recruitment/public-service-employees/pse-prepare/school-based-role-description-library',
      description:
        'The following role descriptions are for school-based public service employee roles....',
    },
    {
      title: 'Department of Education Strategic plan',
      url: 'https://education.nsw.gov.au/about-us/strategies-and-reports/strategic-plan',
      description:
        'The Strategic Plan is the department’s highest-level strategy document, outlining system-wide objectives and direction.',
    },
  ],
};
const supervisorPerformanceAndDevelopmentSectionContent = {
  title: 'Performance and development process',
  tiles: [
    {
      title: 'Supporting non-teaching staff to develop their goals',
      url: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/supervising-performance-and-development/supporting-non-teaching-staff-to-develop-their-goals',
      description:
        'In the first phase of the performance and development process, your role is to support staff to identify and develop work goals for their performance and development plan (PDP).',
    },
    {
      title: 'Supporting meaningful conversations',
      url: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/supervising-performance-and-development/supporting-meaningful-conversations',
      description:
        'Meaningful conversations between a staff member and PDP supervisor are an integral part of the performance and development cycle. Meaningful conversations help us all to be our best.',
    },
    {
      title: 'Meaningful conversations and feedback – useful approaches ',
      url: 'https://education.nsw.gov.au/content/dam/main-education/inside-the-department/human-resources/media/documents/performance/non-teaching-staff-performance-and-development/Meaningful-conversations-useful-approaches.docx',
      description:
        'Download this guide to support your meaningful conversations and feedback.',
    },
    {
      title: 'Performance and development process',
      url: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/performance-and-development-process',
      description:
        'This simple process has three phases: plan, implement and review. Each phase is supported by a key conversation with your performance and development plan (PDP) supervisor.',
    },
    {
      title: 'Policy and procedure',
      url: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools/policies-and-procedures',
      description:
        "The department's performance management and development policy and procedure apply to all non-teaching staff in schools, including...",
    },
    {
      title: 'Performance and development benefits for NTS',
      url: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/non-teaching-staff-in-schools',
      description:
        'The performance and development process for non-teaching staff in schools supports our commitment to developing a skilled, engaged and professional workforce to ensure improved outcomes for our students.',
    },
    {
      title: 'Digital PDP for non-teaching staff in schools',
      url: 'https://education.nsw.gov.au/inside-the-department/human-resources/performance/performance-tools/digital-pdp-for-non-teaching-staff-in-schools',
      description:
        'Non-teaching staff can choose to opt in and use the Digital PDP from 2023...',
    },
  ],
};

export default {
  name: 'UsefulLinks',
  components: {
    Banner,
    TilesSection,
  },
  data() {
    return {
      tab: null,
      tabs: [{ name: 'for non teaching staff' }, { name: 'for supervisors' }],
      ntsSections: [
        performanceAndDevelopmentSectionContent,
        professionalLearningSectionContent,
        roleResponsibilitiesSectionContent,
      ],
      supervisorsSections: [supervisorPerformanceAndDevelopmentSectionContent],
    };
  },
};
</script>

<style lang="scss" scoped>
.container-wrapper,
.container-main {
  background-color: $ads-light-10;
}
:deep .banner > .col {
  padding-bottom: 24px;
}
:deep .v-tab {
  width: 275px;
}
</style>
