<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col>
        <v-card class="mb-5">
          <v-row no-gutters>
            <v-col cols="auto">
              <div class="px-5 px-md-10 pt-5">
                <h1 :class="{ 'mb-5': !section?.notifySupervisorTime }">
                  Professional Goals
                </h1>
                <p
                  v-if="section?.notifySupervisorTime"
                  v-dompurify-html:plaintext="
                    `Submitted: ${formatDateTime(
                      this.section.notifySupervisorTime
                    )}`
                  "
                />
              </div>
            </v-col>
          </v-row>
          <v-row
            :key="`plan-read-only-goal-${goal.id}`"
            v-for="(goal, index) of section.goals"
            no-gutters
          >
            <v-col>
              <div class="mb-0">
                <div class="goal-heading mb-10 px-5 px-md-10 py-5">
                  Goal {{ index + 1 }}: {{ goal.title }}
                </div>
                <div class="px-5 px-md-10 no-break-inside">
                  <p>
                    <strong class="title">
                      <v-icon
                        :color="ADS_Colors.Blue_2"
                        class="mr-2"
                      >
                        mdi-star-outline
                      </v-icon>
                      Description
                    </strong>
                  </p>
                  <IncompleteFieldCard
                    v-if="isIncompleteArchivedPdp && !goal?.description"
                  />
                  <div
                    v-else
                    class="goal-text mb-10"
                    v-dompurify-html="goal?.description ?? ''"
                  />
                </div>
                <hr />
                <div class="px-5 px-md-10 no-break-inside">
                  <p class="no-break-inside">
                    <strong class="title">
                      <v-icon
                        :color="ADS_Colors.Blue_2"
                        class="mr-2"
                      >
                        mdi-calendar-edit
                      </v-icon>
                      Standard descriptors
                    </strong>
                  </p>
                  <IncompleteFieldCard
                    v-if="
                      isIncompleteArchivedPdp && goal?.standards?.length === 0
                    "
                  />
                  <div
                    v-else
                    :class="`${goal.standards}mb-10`"
                  >
                    <TeachingStandardPrint
                      v-for="standard of goal?.standards"
                      :key="`teachingStandard-${goal.id}${standard.id}${standard.focus}${standard.level}`"
                      :canDelete="false"
                      :outlined="false"
                      flat
                      :standard="standard"
                    />
                  </div>
                </div>
                <hr />
                <div
                  v-if="ownerIsPrincipal"
                  class="px-5 px-md-10 no-break-inside"
                >
                  <p>
                    <strong class="title">
                      <v-icon
                        :color="ADS_Colors.Blue_2"
                        class="mr-2"
                      >
                        mdi-comment-account-outline
                      </v-icon>
                      Strategic Improvement Plan
                    </strong>
                  </p>
                  <IncompleteFieldCard
                    v-if="
                      isIncompleteArchivedPdp &&
                      !goal?.sipType &&
                      !goal?.sipOther
                    "
                  />
                  <div v-else>
                    <p
                      class="goal-text"
                      v-dompurify-html:plaintext="
                        sipOptions[goal?.sipType]?.text
                      "
                    />
                    <p
                      class="goal-text mb-10"
                      v-dompurify-html="goal?.sipOther ?? ''"
                    />
                  </div>
                </div>
                <hr v-if="ownerIsPrincipal" />
                <div
                  v-if="ownerIsPrincipal"
                  class="px-5 px-md-10 no-break-inside"
                >
                  <p class="no-break-inside">
                    <strong class="title">
                      <v-icon
                        :color="ADS_Colors.Blue_2"
                        class="mr-2"
                      >
                        mdi-sitemap-outline
                      </v-icon>
                      Key Accountabilities
                    </strong>
                  </p>
                  <IncompleteFieldCard
                    v-if="
                      isIncompleteArchivedPdp &&
                      goal?.accountabilities?.length === 0
                    "
                  />
                  <div
                    :class="`${goal?.accountabilities}mb-10 no-break-inside`"
                  >
                    <KeyAccountabilityPrint
                      v-for="accountability of goal?.accountabilities"
                      :key="`goalaccountability-${goal.id}.${accountability?.id}`"
                      :accountability="accountability"
                      :canDelete="false"
                      :outlined="false"
                      flat
                    />
                  </div>
                </div>
                <hr v-if="ownerIsPrincipal" />
                <div class="px-5 px-md-10 no-break-inside">
                  <p>
                    <strong class="title">
                      <v-icon
                        :color="ADS_Colors.Blue_2"
                        class="mr-2"
                      >
                        mdi-format-list-bulleted
                      </v-icon>
                      Professional learning
                    </strong>
                  </p>
                  <IncompleteFieldCard
                    v-if="
                      isIncompleteArchivedPdp && !goal?.professionalLearning
                    "
                  />
                  <div
                    v-else
                    class="goal-text mb-10"
                    v-dompurify-html="`${goal.professionalLearning ?? ''}`"
                  />
                </div>
                <hr />
                <div class="mt-4 mb-10 px-5 px-md-10 no-break-inside">
                  <p>
                    <strong class="title">
                      <v-icon
                        :color="ADS_Colors.Blue_2"
                        class="mr-2"
                      >
                        mdi-content-copy
                      </v-icon>
                      Evidence
                    </strong>
                  </p>
                  <IncompleteFieldCard
                    v-if="isIncompleteArchivedPdp && !goal?.evidence"
                  />
                  <div
                    v-else
                    class="goal-text"
                    v-dompurify-html="`${goal.evidence ?? ''}`"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <EventLog
      :pdp="this.pdp"
      :section="this.section"
      :owner="this.pdp?.owner"
      :supervisor="this.supervisor"
      class="my-10 px-0 px-md-10 py-8"
    />
  </v-container>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { mdiInformationOutline } from '@mdi/js';
import TeachingStandardPrint from '@/components/print/TeachingStandardPrint';
import { sipOptions } from '@/data/sip';
import KeyAccountabilityPrint from '@/components/print/KeyAccountabilityPrint';
import { formatDateTime, formatName, getFinalSection } from '@/utils';
import { getComments } from '@/components/PlanReadOnly.lib';
import IncompleteFieldCard from '@/components/print/IncompleteFieldCard';
import { USER_TYPE } from '@/data/constants';
import EventLog from '@/components/EventLog';

export default {
  name: 'TSPlanReadOnlyPrint',
  components: {
    KeyAccountabilityPrint,
    TeachingStandardPrint,
    IncompleteFieldCard,
    EventLog,
  },
  data: () => ({
    ADS_Colors,
    icons: {
      mdiInformationOutline,
    },
    sipOptions,
  }),
  props: {
    pdp: {
      type: Object,
      default: () => ({}),
    },
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ownerIsPrincipal() {
      return this.pdp?.owner?.isPrincipal;
    },
    supervisor() {
      return this.pdp?.pdp_user_associations?.find(
        (user) =>
          user.type === USER_TYPE.supervisor &&
          user.user_id === this.section.supervisor_id
      )?.user;
    },
    comments() {
      return getComments(
        this.section,
        this.pdp?.owner,
        this.supervisor,
        null,
        null,
        this.pdp
      );
    },
    isIncompleteArchivedPdp() {
      const finalSection = getFinalSection(this.pdp?.sections);
      return !this.pdp?.active && !finalSection?.complete;
    },
  },
  methods: {
    formatName,
    formatDateTime,
  },
};
</script>

<style lang="scss" scoped>
.goal-heading {
  background-color: rgba(108, 172, 228, 0.2);
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.goal-text {
  color: $ads-dark;
  white-space: pre-line;
  font-size: 1rem;
}

.overview {
  background-color: $ads-light-10;
  padding: 1.5rem 2rem;

  .overview-chip {
    position: relative;
    top: -0.5rem;
  }

  .overview-content {
    width: 100%;
    padding-top: 1rem;
  }
}

.goal + .goal {
  margin-top: 2rem;
  padding-top: 2.5rem;
  border-top: 1px solid $ads-light-20;
}

hr {
  border: solid $ads-light-20;
  border-width: thin 0 0 0;
  margin: 16px 0 32px;
  break-inside: avoid;
}
</style>
