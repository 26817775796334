import { get, post } from '../http';
import { Pdp, User } from '@/schema';

//ToDo: Refactor most of these once we start using JSON web tokens?
// the userId should be in there somewhere

const basePath = process.env.VUE_APP_IS_LAMBDA ? '/v2' : '';

interface PostUserLogin {
  schoolPdp: Pdp[] | [];
  superviseePdp: Pdp[] | [];
  pdp?: Pdp;
  loggedInUser: User;
}

const getUserLogin = async () => {
  // console.log('getUserLogin');
  // console.log(process.env.IS_LAMBDA);
  // console.log(`basePath = ${basePath}`);
  const url = `${basePath}/users/login`;
  const response = await get(url);
  if (response.data) {
    return response.data;
  }
  return undefined;
};

const getUsersByEmail = async (email: string): Promise<User[] | undefined> => {
  const response = await get<User[]>(
    `${basePath}/users/search?staffDoEEmailAddress=${email}`
  );
  if (response.data) {
    return response.data;
  }
  return undefined;
};

const postUserLogin = async (): Promise<PostUserLogin | undefined> => {
  const response = await post('/users/login');
  if (response.data) {
    return response.data;
  }
  return undefined;
};

export default {
  getUsersByEmail,
  postUserLogin,
  getUserLogin,
};
