<template>
  <v-card class="px-0 px-md-10 py-8 mt-6">
    <v-row class="px-5 px-md-0">
      <v-col>
        <p>
          <b class="title">
            <v-icon
              :color="ADS_Colors.Blue_2"
              class="mr-2"
            >
              mdi-archive-arrow-down-outline
            </v-icon>
            Archive
          </b>
        </p>
      </v-col>
    </v-row>
    <v-card class="mx-0 pa-5 mb-5">
      <p
        class="pa-0 ma-0"
        v-dompurify-html:sanitize="
          `<strong>${formatName(pdp?.owner)} ${formatDateTime(
            pdp?.archived_at
          )} </strong>`
        "
      />
      <p
        class="pa-0 ma-0"
        v-dompurify-html:sanitize="pdp?.archive_reason ?? ''"
      />
    </v-card>
  </v-card>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { formatDateTime, formatName } from '@/utils';

export default {
  name: 'IncompleteArchiveCard',
  props: {
    pdp: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    ADS_Colors,
  }),
  methods: {
    formatName,
    formatDateTime,
  },
};
</script>

<style scoped lang="scss"></style>
