<template>
  <div>
    <label
      class="boxlabel"
      :class="`${
        !valid && validationMessage !== null ? 'error--text shake' : ''
      }`"
      v-dompurify-html:plaintext="label"
    />
    <VueEditor
      v-model.trim="textValue"
      ref="vue-editor"
      :editorToolbar="customToolbar"
      :placeholder="textValue && disabled ? '' : placeholder"
      @text-change="handleInput"
      onpaste="return !!event.clipboardData.getData('text');"
      class="mb-2 editor"
      :class="`${!valid && validationMessage !== null ? 'validation' : ''}`"
      @blur="validate"
      :disabled="disabled"
    />
    <p class="message px-3 error--text">
      {{ !valid ? validationMessage : '' }}
    </p>
  </div>
</template>

<script>
import { VueEditor, Quill } from 'vue2-editor';
import { trimHtml } from '@/utils';

export default {
  name: 'RichTextField',
  components: {
    VueEditor,
  },
  props: {
    value: {
      type: String,
      default: () => null,
    },
    label: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    lazy: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  inject: { form: { from: 'form', default: null } },
  created() {
    if (this.form) {
      this.form.register(this);
    }
  },
  beforeMount() {
    if (!this.lazy) {
      this.validate();
    }
  },
  data() {
    return {
      textValue: this.value,
      valid: true,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }], // dropdown with defaults from theme
        ['link'],
        ['clean'], // remove formatting button
        // For all toolbars options
        // https://github.com/davidroyer/vue2-editor/blob/master/src/helpers/fullToolbar.js
      ],
    };
  },
  computed: {
    validationMessage() {
      for (const rule of this.rules) {
        const message = rule(this.textValue);
        switch (message) {
          case true:
            break;
          default:
            return message;
        }
      }
      return true;
    },
  },
  mounted() {
    // Vue2Editor is built upon Quill, so Quill's APIs can be used
    // prevents pasting images into quill container (https://github.com/quilljs/quill/issues/1108#issuecomment-757547630)
    this.$refs['vue-editor'].quill.clipboard.addMatcher('IMG', () => {
      const Delta = Quill.import('delta');
      return new Delta().insert('');
    });
    this.$refs['vue-editor'].quill.clipboard.addMatcher('PICTURE', () => {
      const Delta = Quill.import('delta');
      return new Delta().insert('');
    });
  },
  watch: {
    value(value) {
      this.textValue = value;
    },
    textValue: {
      handler() {
        this.validate();
      },
    },
  },
  methods: {
    handleInput() {
      this.$emit('input', trimHtml(this.textValue));
    },
    validate() {
      this.valid = this.validationMessage === true;
      this.$emit('validated', this.valid);
      return this.valid;
    },
  },
};
</script>

<style scoped lang="scss">
.message {
  //color: #d7153a !important;
  //caret-color: #d7153a !important;
  line-height: 12px;
  word-break: break-word;
  font-size: 12px;
  text-align: left;
}

.editor {
  //transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  border: 1px solid #9e9e9e;
  border-radius: 4px;
}

.validation {
  //transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  border: 2px solid #d7153a;
}

.boxlabel {
  //display: block;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  right: auto;
  position: absolute;
  font-weight: 700;
  color: #041e42;
  //margin-left: 10px;
  padding: 0 8px 0 3px;
  background-color: #ffffff;
  transform-origin: top left;
  transform: translateY(-9px) translateX(10px) scale(0.75);
}

.shake {
  animation: v-shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
}

:deep .ql-toolbar.ql-snow {
  border-color: #ccc;
  border-width: 0 0 1px 0;
}

:deep .ql-container.ql-snow {
  border-width: 0;
}
</style>
