<template>
  <v-card outlined>
    <v-card-text class="pa-7">
      <p class="mb-0 body-1 black--text">This field is incomplete</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'IncompleteFieldCard',
};
</script>

<style scoped lang="scss">
.v-card {
  border-color: $ads-grey-03;
}
</style>
