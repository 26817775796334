<template>
  <div>
    <v-row justify="space-between">
      <v-col class="flex-grow-1">
        <h4 class="display-1">Observations</h4>
      </v-col>
      <v-col class="text-center flex-shrink-1 flex-grow-0">
        <v-tooltip
          v-model="showTooltipMessage"
          color="primary"
          left
          max-width="300"
        >
          <template #activator="{ attrs }">
            <v-btn
              icon
              v-bind="attrs"
              @click="showTooltipMessage = !showTooltipMessage"
            >
              <v-icon
                color="primary"
                dark
              >
                help_outline
              </v-icon>
            </v-btn>
          </template>
          <p v-if="loggedInUser.isPrincipal">
            Documented observations of leadership practices by the PDP
            supervisor of executives and principals are to be conducted.
          </p>
          <p v-else>
            The level of experience of the teacher will inform the number of
            observations of teaching practice. There needs to be a minimum of
            two documented observations per year.
          </p>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col :class="`${!editMode ? 'segment-divider' : ''}`">
        <p v-if="editMode">You can upload all documented observations here.</p>
        <p>
          <strong>Supported Files:</strong>
          <em>
            Word and Excel documents, Images (JPEG and PNG), and PDF documents.
            Special characters are not supported in file names.
          </em>
        </p>
      </v-col>
    </v-row>
    <v-row v-if="showWarning">
      <v-col cols="6">
        <Alert
          type="warning"
          text="You have added the maximum number of observations to your PDP."
          subtext="Please work with your PDP supervisor to refine your goals."
          inPage
          :backgroundColour="true"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="showError">
        <Alert
          type="error"
          text="Special characters are not supported in file names."
          subtext="Please remove special characters in the file name and upload again."
          inPage
          :backgroundColour="true"
        />
      </v-col>
    </v-row>
    <v-row v-if="editMode">
      <v-col class="segment-divider">
        <p class="title">Upload an observation document</p>
        <v-row>
          <v-col
            md="6"
            class="text-right"
          >
            <v-form
              :ref="'observerForm'"
              v-model="isValid"
              lazy-validation
            >
              <AdsTextField
                v-model="obsTitle"
                data-test="observation-title-input"
                :disabled="isReadOnly"
                :rules="[rules.stringFieldRequired]"
                label="Document title"
              />
              <v-combobox
                v-model="observer"
                :items="observers"
                :rules="[rules.observerFieldRequired]"
                :search-input.sync="searchObserver"
                :disabled="isReadOnly"
                clearable
                item-text="staffDoEEmailAddress"
                item-value="id"
                label="Observer"
                outlined
                placeholder="Start typing to search"
                return-object
              />
              <AdsButton
                id="uploader"
                :disabled="uploadButtonDisabled || showWarning"
                secondary
                @click="openSelectFile"
              >
                Choose file
              </AdsButton>
              <div
                v-if="errorMessage !== null"
                class="mt-4 text-align-left"
              >
                <p class="subtitle-1 error-text">
                  {{ errorMessage }}
                </p>
              </div>
            </v-form>
          </v-col>
        </v-row>
        <input
          ref="uploader"
          accept=".doc, .docx, .jpg, .jpeg, .pdf, .png, .xls, .xlsx"
          hidden
          multiple
          type="file"
          @change="onFileSelection"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <p class="title">Uploaded documented observations</p>
        <p v-if="editMode">
          Total storage used: {{ fileSizePrint(totalFileSize) }} MB / 20 MB
        </p>
        <p v-if="pdp?.observations.length === 0">
          No documented observations uploaded yet
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col :class="`${editMode ? '' : 'pt-6'}`">
        <FileCard
          v-for="file in pdp?.observations"
          :download="true"
          :key="`observation${file?.id}-observationfile${file.id}`"
          :file="file"
          :editMode="editMode"
          @downloadFile="downloadFile(file.id, file.fileName)"
          @deleteFile="handleDelete(file.id)"
          class="mb-6"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdsButton from '@nswdoe/doe-ui-core/src/AdsButton/AdsButton.vue';
import AdsTextField from '@nswdoe/doe-ui-core/src/AdsTextField/AdsTextField.vue';
import { mapState } from 'vuex';
import { debounce } from 'lodash';
import api from '@/api';
import { calculateTotalFileSize } from '@/utils';
import { clone, equals } from 'ramda';
import FileCard from '@/components/FileCard.vue';
import { Alert } from '@nswdoe/doe-ui-core';
import validators from '@/lib/validators';

export default {
  name: 'TSPdpObservations',
  components: {
    FileCard,
    AdsButton,
    AdsTextField,
    Alert,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    pdp: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      obsTitle: '',
      isValid: true,
      observer: null,
      searchObserver: '',
      loadingObservers: false,
      errorMessage: null,
      showTooltipMessage: false,
      pdpObservations: [],
      maximum: 10,
      showWarning: false,
      showError: false,
      rules: {
        observerFieldRequired: validators.userFieldRequired,
        stringFieldRequired: validators.stringFieldRequired,
      },
    };
  },
  created() {
    this.debouncedSearch = debounce(async (value) => {
      await this.getObservers(value);
    }, 150);
  },
  watch: {
    searchObserver(newValue, oldValue) {
      if (
        newValue &&
        newValue.trim().length > 1 &&
        !equals(newValue, oldValue) &&
        oldValue
      ) {
        this.debouncedSearch(newValue);
      }
    },
    currentObservations: {
      handler() {
        this.checkMaximum();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      loggedInUser: (state) => state.loggedInUser,
      observers: (state) => state.user.usersByIdentifier,
    }),
    totalFileSize() {
      return this.pdp ? calculateTotalFileSize(this.pdp) : 0;
    },
    uploadButtonDisabled() {
      return (
        this.rules.stringFieldRequired(this.obsTitle) !== true ||
        this.rules.observerFieldRequired(this.observer) !== true
      );
    },
    isReadOnly() {
      return !this.pdp.sections.find((s) => !s.complete);
    },
    currentObservations() {
      return this.pdp?.observations;
    },
  },
  methods: {
    checkMaximum() {
      this.showWarning = this.currentObservations.length >= this.maximum;
      return this.showWarning;
    },
    async getObservers(value) {
      await this.$store.dispatch('user/searchUsersByEmail', value);
    },
    openSelectFile() {
      this.showError = false;
      const validated = this.$refs.observerForm.validate();
      if (validated) {
        this.errorMessage = null;
        this.$refs.uploader.click();
      }
    },
    fileSizePrint(size) {
      return (size / 1024 / 1024).toFixed(2);
    },
    async onFileSelection(event) {
      const allowedExtensions = [
        '.png',
        '.jpg',
        '.jpeg',
        '.pdf',
        '.doc',
        '.docx',
        '.xls',
        '.xlsx',
      ];

      function pathInfo(s) {
        s = s.match(/(.*?\/)?(([^/]*?)(\.[^/.]+?)?)(?:[?#].*)?$/);
        return { path: s[1], file: s[2], name: s[3], ext: s[4] };
      }
      const path = event.target.value.split('\\');
      const fileName = path[path.length - 1];
      const fileInfo = pathInfo(fileName);
      const fileExtension = fileInfo.ext;
      try {
        if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
          this.errorMessage = 'Invalid file type';
          return;
        }
      } catch (e) {
        this.showError = true;
        return;
      }

      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.addEventListener('load', async (e) => {
        if (e.total > 4194304) {
          this.errorMessage = `File size of ${this.fileSizePrint(
            e.total
          )} MB is too large. Please upload a file less than 4 MB.`;
          return;
        }

        if (this.totalFileSize + e.total > 20971520) {
          this.errorMessage = `File size of ${this.fileSizePrint(
            e.total
          )} MB will put you over the 20 MB total file limit.`;
          return;
        }

        const observation = {
          pdp_id: this.pdp.id,
          observer_id: parseInt(this.observer.id),
          title: this.obsTitle,
          fileName: fileName,
          data: e.target.result.split(',')[1],
          size: e.total,
        };
        const pdp = clone(this.pdp);
        pdp.observations.push(observation);
        await this.$store.dispatch('pdp/savePdp', pdp);
        this.obsTitle = null;
        this.observer = null;
        this.$refs.observerForm.resetValidation();
        this.isValid = true;
      });
    },
    async downloadFile(observationId, fileName) {
      const observationFile =
        await api.pdp.getObservationWithFile(observationId);
      if (observationFile) {
        const link = document.createElement('a');
        link.href =
          'data:application/octet-stream;base64,' + observationFile.data;
        link.download = fileName;
        link.click();
      }
    },
    async handleDelete(observationId) {
      const pdp = clone(this.pdp);
      pdp.observations = pdp.observations?.map((observation) => {
        if (observation.id === observationId) {
          observation.data = null;
          observation.markForDelete = true;
        }
        return observation;
      });
      await this.$store.dispatch('pdp/savePdp', pdp);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.error-text {
  color: $ads-error-red;
}

.text-align-left {
  text-align: left;
}

.dark-border-bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $ads-light-40;
}

.segment-divider::after {
  content: '';
  display: block;
  width: calc(100% + 80px);
  border-top: 1px solid $ads-light-20;
  margin: 32px -40px;
}
</style>
