<template>
  <v-row>
    <v-col>
      <ErrorPage
        class="container"
        title="You do not have permission to view this page."
        :is403Page="true"
      >
        <v-btn>Go Back</v-btn>
      </ErrorPage>
    </v-col>
  </v-row>
</template>

<script>
import ErrorPage from '@nswdoe/doe-ui-core/src/ErrorPage/ErrorPage.vue';

export default {
  name: 'NotAuthorized',
  components: {
    ErrorPage,
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 12px;
  padding-top: 20vh;
  padding-bottom: 20vh;
}
</style>
