<template>
  <Wrapper
    class=""
    cardClass="my-8 mx-md-10 pa-0 pt-md-9 pb-md-7"
    :isMobile="isMobile"
  >
    <v-row
      class="flex-column"
      no-gutters
    >
      <v-col class="px-4 px-md-10">
        <v-card
          class="pa-8 pa-md-0 rounded-12"
          color="primary-teal"
          elevation="0"
        >
          <v-row no-gutters>
            <v-col
              class="pa-md-8"
              md="7"
              lg="8"
            >
              <v-card-title
                class="pa-0 pb-4 font-weight-bold primary-blue--text"
              >
                Welcome to the Non-Teaching Staff Performance and Development
                Plan (PDP)
              </v-card-title>
              <v-card-text class="px-0 grey-01--text">
                The performance and development plan is used to record a staff
                member's goals, actions, development, mid-year self-assessment
                and annual review.
              </v-card-text>
              <v-card-actions class="pl-0">
                <AdsButton
                  buttonText="Create your PDP"
                  icon="mdi-arrow-right"
                  depressed
                  @click="createPdp()"
                />
              </v-card-actions>
            </v-col>
            <v-col
              v-if="!isMobile"
              class="pa-md-8 d-flex justify-center align-center"
              md="5"
              lg="4"
            >
              <v-img
                max-height="219"
                max-width="219.61"
                src="../../public/chat-bubbles.svg"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <h1 class="mt-6 mt-md-10 px-4 px-md-10">
          <v-icon
            class="mr-3"
            color="success"
          >
            mdi-av-timer
          </v-icon>
          <span class="text-h6 font-weight-bold grey-01--text">
            Indicative Timeframe
          </span>
        </h1>
        <v-divider class="mt-3 mb-2"></v-divider>
        <v-list-item
          v-for="(part, index) in pdpParts"
          :key="part.title"
          class="px-4 px-md-10"
        >
          <v-list-item-content
            :class="{ 'pb-0': index === pdpParts.length - 1 }"
          >
            <v-list-item-title class="font-weight-bold primary-blue--text">
              {{ part.title }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="part.description"
              class="mt-2 grey-01--text font-italic"
            >
              {{ part.description }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-for="(subheading, idx) in part.subheadings"
              :key="idx"
              class="grey-01--text mt-2"
            >
              {{ subheading }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <p class="mt-8 px-4 px-md-10">
          A new PDP should be commenced in temporary roles longer than 10 weeks
          duration.
        </p>
      </v-col>
    </v-row>
  </Wrapper>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core';
import { mapActions, mapGetters } from 'vuex';
import Wrapper from '@/components/Wrapper.vue';

export default {
  name: 'NTSLandingPage',
  components: { AdsButton, Wrapper },
  data() {
    return {
      pdpParts: [
        {
          title:
            'PLAN - Commence beginning of term 1 and complete by mid-term 1.',
          subheadings: [
            'Part A – Work Goals',
            'Part B – Career Goals (optional)',
          ],
        },
        {
          title:
            'IMPLEMENT - Commence end of term 2 and complete by beginning of term 3.',
          subheadings: ['Part C – Mid-year self-assessment'],
        },
        {
          title: 'REVIEW - Complete by end of term 4.',
          subheadings: [
            'Part D – Staff member annual review',
            'Part E – Supervisor annual review',
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['loggedInUser', 'ownedPdp']),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    hasActivePdp() {
      return this.ownedPdp?.filter((pdp) => pdp.active)?.length > 0;
    },
  },
  methods: {
    ...mapActions('pdp', ['createUserPdp', 'getOwnedPdp']),
    async createPdp() {
      this.$emit('createNtsPdp');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .v-card {
  &__title {
    font-size: 34px;
    line-height: 40px;
    word-break: break-word;
  }
  &__text {
    font-size: 16px;
    line-height: 24px;
  }
}
:deep .v-list-item__title,
.v-list-item__subtitle {
  font-size: 16px;
  line-height: 22px;
  white-space: normal;
}
.rounded-12 {
  border-radius: 12px;
}
@media screen and (max-width: 959px) {
  :deep .v-card {
    &__title {
      font-size: 20px;
      line-height: 25px;
    }
    &__text {
      font-size: 16px;
    }
  }
}
</style>
