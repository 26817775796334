<template>
  <v-row no-gutters>
    <v-col :cols="openDialog ? 'auto' : 7">
      <h3 class="title px-0 pb-6">This PDP has been edited</h3>
      <v-card
        class="px-6 mb-6"
        :color="ADS_Colors.PRIMARY_TEAL"
        elevation="0"
        rounded="lg"
      >
        <v-card-text class="px-0 body-1 grey-01--text">
          Select ‘close and refresh’ and the page will be refreshed.
          <br />
          <b>Note:</b>
          If you have already signed this PDP, you may need to sign again to
          confirm the changes. Always close the browser window for privacy and
          to ensure the information is updated.
        </v-card-text>
      </v-card>
      <AdsButton
        buttonText="Close & refresh"
        icon="mdi-arrow-right"
        @click="closeExpiredPdpDialog"
      />
    </v-col>
  </v-row>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';

export default {
  name: 'PdpUpdateDialog',
  components: { AdsButton },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ADS_Colors,
    };
  },
  methods: {
    closeExpiredPdpDialog() {
      this.$emit('closeExpiredPdpDialog');
    },
  },
  emits: ['closeExpiredPdpDialog'],
};
</script>
<style scoped lang="scss">
.confirm-box {
  background-color: white;
  border: $ads-navy-dark-1 solid 2px !important;
}
</style>
