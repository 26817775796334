<template>
  <div>
    <v-row no-gutters>
      <v-col cols="auto">
        <FeatureIcon
          :backgroundColor="ADS_Colors.PRIMARY_TEAL"
          icon="mdi-archive-arrow-down-outline"
        />
      </v-col>
      <v-col class="ml-5">
        <h3 class="title px-0 mb-2">Archive an incomplete PDP</h3>
        <p>
          This PDP has not been finalised. Please provide a reason for archiving
          an incomplete PDP.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form
          ref="archivePdpForm"
          v-model="isValid"
          lazy-validation
        >
          <AdsTextArea
            v-model="formPdp.archive_reason"
            counter="100"
            label="Reason"
            placeholder="E.g. I was on extended leave. My PDP is no longer relevant, and I need to start a new PDP."
            persistent-placeholder
            :rules="[rules.stringLengthRequired, rules.maxStringLength]"
            auto-grow
            rows="8"
          />
          <div class="d-flex flex-row mt-3">
            <AdsButton
              :disabled="isDisabled"
              buttonText="Archive PDP"
              class="ml-auto px-3"
              type="submit"
              secondary
              color="error"
              icon="mdi-archive-arrow-down-outline"
              @click.prevent="archive"
            />
            <AdsButton
              buttonText="Cancel"
              class="ml-4"
              @click.prevent="cancel"
            />
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AdsButton, AdsTextArea, FeatureIcon } from '@nswdoe/doe-ui-core';
import validators from '@/lib/validators';
import { ADS_Colors } from '@nswdoe/doe-ui-core/src/ads-colors';
import { archivePdp } from '@/lib/pdp';

export default {
  name: 'ArchivePdpModal',
  components: { AdsButton, AdsTextArea, FeatureIcon },
  data: () => ({
    ADS_Colors,
    isValid: true,
    rules: {
      stringLengthRequired: validators.stringLengthRequired(10),
      maxStringLength: validators.maxStringLengthRule(100),
    },
  }),
  computed: {
    ...mapGetters(['formPdp']),
    isDisabled() {
      return !(
        this.rules.stringLengthRequired(this.formPdp?.archive_reason) ===
          true &&
        this.rules.maxStringLength(this.formPdp?.archive_reason) === true
      );
    },
  },
  methods: {
    archive() {
      const validated = this.$refs.archivePdpForm.validate();
      if (validated) {
        archivePdp(this.formPdp);
        this.$emit('save');
      }
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>
