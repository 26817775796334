import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { ADS_Colors } from '@nswdoe/doe-ui-core'; // use pre-defined ADS colors constants

Vue.use(Vuetify);

export const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: ADS_Colors.Navy,
        'primary-blue': ADS_Colors.PRIMARY_BLUE,
        'primary-red': ADS_Colors.PRIMARY_RED,
        'primary-teal': ADS_Colors.PRIMARY_TEAL,
        secondary: ADS_Colors.Light_Blue,
        accent: ADS_Colors.Blue_1,
        success: ADS_Colors.Blue_2,
        'success-green': ADS_Colors.Success_Green,
        error: ADS_Colors.Error_Red,
        white: ADS_Colors.White,
        'grey-01': ADS_Colors.GREY_01,
      },
    },
  },
});
