<template>
  <!--  taken from Banner component, slots didn't work right-->
  <v-container
    :class="'banner banner1 ml-0 pt-0 pt-md-3 px-0 px-md-3 pb-0 pb-md-6'"
    fluid
  >
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-row
        class="ml-5"
        v-bind="{ 'justify': 'start' }"
      >
        <v-col class="pl-0">
          <div class="d-flex align-start float-left">
            <div class="pt-1">
              <v-icon
                class="mx-2 material-icons-outlined"
                x-large
              >
                file_copy
              </v-icon>
            </div>
            <div class="d-flex flex-column">
              <h1 class="appTitle">Management</h1>
              <p class="appSubtitle mb-5">
                PDP statements from your team or school
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-container fluid>
        <v-row>
          <v-col
            v-for="content in chartCards"
            :key="content.title"
            align-self="center"
            class="pl-12 pr-8 py-6"
            cols="6"
          >
            <v-card
              style="background-color: #2d4586; min-height: 315px"
              v-if="content.showCard"
            >
              <v-card-title
                class="ml-5 pt-5 text-h5"
                style="color: white"
              >
                {{ content.title }}
              </v-card-title>
              <v-card-text style="background-color: #2d4586">
                <v-fade-transition>
                  <v-skeleton-loader
                    v-if="content.loadCard"
                    v-show="content.loadCard"
                    type="image"
                    height="183px"
                    class="px-6"
                  />
                  <PdpCompletion
                    v-if="!content.loadCard"
                    v-show="!content.loadCard"
                    :stats="content.stats"
                    :type="content.type"
                    areaLabel=""
                    style="background-color: #2d4586"
                  />
                </v-fade-transition>
                <p
                  v-if="content.type === CHART_TYPE.team"
                  class="pt-6 mb-0 ml-6 white--text"
                >
                  Team overview represents active team member PDPs.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-carousel
      v-else
      height="auto"
      hide-delimiters
    >
      <v-carousel-item
        v-for="content in chartCards"
        :key="content.title"
      >
        <v-sheet class="mt-2 mb-4 px-5 transparent">
          <v-card
            style="background-color: #2d4586; min-height: 300px"
            v-if="content.showCard"
          >
            <v-card-title
              class="ml-5 pt-5 text-h5"
              style="color: white"
            >
              {{ content.title }}
            </v-card-title>
            <v-card-text style="background-color: #2d4586">
              <v-fade-transition>
                <v-skeleton-loader
                  v-if="content.loadCard"
                  v-show="content.loadCard"
                  type="image"
                  height="183px"
                  class="px-6"
                />
                <PdpCompletion
                  v-if="!content.loadCard"
                  v-show="!content.loadCard"
                  :stats="content.stats"
                  :type="content.type"
                  areaLabel=""
                  style="background-color: #2d4586"
                />
              </v-fade-transition>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import PdpCompletion from '@/components/PdpCompletion.vue';
import { CHART_TYPE } from '@/data/constants';

export default {
  name: 'SupervisorCard',
  components: { PdpCompletion },
  data() {
    return {
      CHART_TYPE,
    };
  },
  props: {
    isLoading: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    ...mapGetters('pdp', ['pdpStats']),
    chartCards() {
      return [
        {
          loadCard: this.isLoading,
          showCard: !!this.loggedInUser,
          title: 'Team Overview',
          stats: this.pdpStats?.team_stats,
          type: CHART_TYPE.team,
        },
        {
          loadCard: this.isLoading,
          showCard: this.loggedInUser?.isPrincipal,
          title: 'School Overview',
          stats: this.pdpStats?.school_stats,
          type: CHART_TYPE.school,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $ads-navy;
  color: $ads-white;
}

.banner .col {
  padding-top: 30px;
  padding-bottom: 0;
}

.appSubtitle {
  font-size: 16px;
  padding: 0 14px;
  margin-bottom: 0;
}

.appTitle {
  font-size: 40px;
  font-weight: 600;
  display: inline-block;
  padding: 0 12px;
  vertical-align: bottom;
}

i.v-icon {
  color: $ads-white;
}

.banner1 {
  background-image: url(@/assets/management-banner.png);
  background-position: right;
}

:deep .v-window__prev,
:deep .v-window__next {
  background: none;

  .v-btn {
    color: $ads-white;
  }
}

:deep .v-window {
  &__prev {
    left: -32px;
  }

  &__next {
    right: -32px;
  }
}
</style>
